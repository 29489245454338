import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class ListerEngineSpareDetails extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white font-weight-200 m-b10">Lister Type Engine Spares Details </h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Agriculture Equipments</li>
                                        <li>Lister Type Engine Spares Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b10">Lister Type Engine Spares Details</h2><br />
                                        <h3> Diesel Engine Spares </h3>
                                        <h6>Diesel engines just like any other machine needs to replace spares due to wear and tear of parts and we do have solution to
                                        supply Diesel Engine spare parts for Diesel Engines, Generator set , Water Pumps etc.</h6>  
                                        <h6>We can supply wide range of Lister spares and we carry a stock of some old and rare spare parts like
                                        O/S 12HP, 14HP, 16HP, 18HP & TV-2 Engine Parts</h6>
                                        <h6>Further we can also customize or procure diesel engine parts as per your sample, design, drawing etc.</h6><br />
                                         
                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/>
                                        <div class="row">
                                        
                                            <div class="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/Aditya impex Jpg/02 petter-type engine spares.jpg")}/>
                                            </div>
                                            <div class="col-lg-12 m-b30">
                                                {/* <img alt="" src={require("../../images/portfolio/HAND DEEPWELL SPARE PARTS.jpg")}/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3">
                                    <ul>
                                            <li> Connecting Rod</li>
                                            <li> Camshaft Gears</li>
                                            <li>Valve Rocker</li>
                                            <li>Valve Tappet</li>
                                            <li>Taper Roller Bearing</li>
                                            <li>Valve Sets</li>
                                            <li>Piston</li>
                                            <li>Fuel Filters</li>
                                        </ul>                                        
                                    </div>
                                    <div class="col-lg-3">
                                        
                                    <ul>
                                            <li>Cylinder Blocks</li>
                                            <li>Crankshaft Gears</li>
                                            <li>Bearings</li>
                                            <li>Fuel Injections like Nozzle, Plunger, D.Valve</li>
                                            <li>Exhaust Silencer</li>
                                            <li>Oil Seals</li>
                                            <li>Piston Rings</li>
                                            <li>Fly wheel</li>
                                        </ul>

                                    </div>
                                    <div class="col-lg-3">
                                    <ul>
                                            <li>Rocker Assembly & Parts</li>
                                            <li>Liner Piston Set</li>
                                            <li>Engine Valve</li>
                                            <li>Crankcase</li>
                                            <li>Valve Guide</li>
                                            <li>Bushes</li>
                                            <li>Fuel Tanks</li>
                                            <li>Cylinder Liner – Sleeves</li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-3">
                                    <ul>
                                            <li>Air Cleaner</li>
                                            <li> Cylinder heads</li>
                                            <li>  Camshafts</li>
                                            <li> Thrust Washers</li>
                                            <li> Fuel Pump</li>
                                            <li> Piston Pin</li>
                                            <li> Oil Filters</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                 
            </>
        )
    }
}
export default ListerEngineSpareDetails;