import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class SolarSystem extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Solar System Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Non Renewable Energy</li>
                                        <li>Solar System Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Solar System Details:</h2>
                                      <br/>
                                      <h3 class="text-black font-weight-600 m-b15">Solar Panel 330W / 24V Poly</h3>
                                      <br/>
                                      <ul>
                                        <li>Poly crystalline Cells type Panel</li>
                                        <li>Capacity - 330 W, 24V</li>
                                        <li>Voltage: Voltage at Max Power (Vmax) - 38.03V, Open Circuit Voltage (Voc) - 45.53V</li>
                                        <li>Current: Current at Max Power (imax) - 8.68A, Short Circuit Current (isc) - 9.22A</li>
                                        <li>25 years performance warranty.</li>
                                        <li>Compliance to IEC standards.</li>
                                        <li>Best in Class conversion efficiency.</li>
                                        <li>Anti-reflective coating and back surface (BSF) for more light absorption</li>
                                        <li>Advanced EVA (ethyl vinyl acetate) encapsulation to give better
                                        protection to modules.</li>
                                        <li>High strength light-weight aluminum frame design for high torsion
                                        resistance against winds and snow loads.</li>
                                        <li>PID resistance Technology (Potential-Induced Degradation) for safety
                                        against substantial power loss due to stray currents triggered by certain
                                        climate conditions.</li>
                                        <li>Excellent Low light performance in low visibility in clouds, evening, and
                                        morning.</li>
                                      </ul>

                                       

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                        <div><br/><br/><br/><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img alt="" style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/solarpanel copy.jpg")}/>

                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                
            </>
        )
    }
}
export default SolarSystem;
