import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';



class ComponentDevHeader extends Component {

    state = {
        fixed:false
    }

    componentDidMount() {

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }

    }

    handleStateChange = (status)=> {
        const fixed = status.status === Sticky.STATUS_FIXED ? true : false
        
        this.setState({
            fixed
        })
        
    }

    render() {
        const active = this.state.fixed ? "is-fixed" : ""
        return (
            <>
             <Sticky 
             innerZ={999} 
             enabled={true}
             onStateChange={this.handleStateChange}
             >
                <header class="site-header mo-left header-transparent header navstyle3">
                    <div class={`sticky-header main-bar-wraper ${active} navbar-expand-lg`}>
                        <div class="main-bar bg-black clearfix ">
                            <div class="container clearfix">
                                <div class="logo-header mostion">
                                    <Link to="/"><img src={require("../../images/aditya_logo.png")} alt=""/></Link>
                                </div>
                                
                                <button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                
                                <div class="extra-nav">
                                    <div class="extra-cell">
                                        <button id="quik-search-btn" type="button" class="site-button-link"><i class="la la-search"></i></button>
                                    </div>
                                </div>
                                
                                <div class="dlab-quik-search ">
                                    <form action="#">
                                        <input name="search" value="" type="text" class="form-control" placeholder="Type to search"/>
                                        <span id="quik-search-remove"><i class="ti-close"></i></span>
                                    </form>
                                </div>
                                
                                <div class="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                    <div class="logo-header d-md-block d-lg-none">
                                        <Link to="/"><img src={require("../../images/aditya_logo.png")} alt=""/></Link>
                                    </div>
                                    <ul class="nav navbar-nav">	
                                        <li class="active has-mega-menu homedemo"> <Link to="#">Home<i class="fa fa-chevron-down"></i></Link>
                                            <ul class="mega-menu">
                                                    <li><Link to={'/componentDevelopment'}><img src={require('../../demo/pic3.jpg')} alt="" /><span>Home -Default</span></Link></li>
                                                    <li><Link to={'/industry'}><img src={require('../../demo/pic1.jpg')} alt="" /><span>Home - Industry</span></Link></li>
                                                    <li><Link to={'/agriculture'}><img src={require('../../demo/pic8.jpg')} alt="" /><span>Home - Agriculture Equitment</span></Link></li>
                                                    <li><Link to={'/construction'}><img src={require('../../demo/pic5.jpg')} alt="" /><span>Home - Construction Machinery</span></Link></li>
                                                    <li><Link to={'/workshop'}><img src={require('../../demo/pic14.jpg')} alt="" /><span>Home - Workshop Machinery</span></Link></li>
                                                    <li><Link to={'/nonRenewableEnergy'}><img src={require('../../demo/pic6.jpg')} alt="" /><span>Home - Non Renewable Energy</span></Link></li>
                                                    <li><Link to={'/tender'}><img src={require('../../demo/pic3.jpg')} alt="" /><span>Home - Project To Tender Division</span></Link></li>
                                            </ul>
                                        </li>
                                        <li class="has-mega-menu"> <Link to="#">About us</Link>
                                           
                                        </li>
                                        <li class=""> <Link to="#">Portfolio<i class="fa fa-chevron-down"></i></Link>
                                            <ul class="sub-menu">
                                                <li><Link to={'/industry'}><span> Industry</span></Link></li>
                                                <li><Link to={'/agriculture'}><span> Agriculture Equitment</span></Link></li>
                                                <li><Link to={'/construction'}><span> Construction Machinery</span></Link></li>
                                                <li><Link to={'/workshop'}><span> Workshop Machinery</span></Link></li>
                                                <li><Link to={'/componentDevelopment'}><span> Precision Component Development</span></Link></li>
                                                <li><Link to={'/nonRenewableEnergy'}><span> Non Renewable Energy</span></Link></li>
                                                <li><Link to={'/tender'}><span> Project To Tender Division</span></Link></li>
                                            </ul>
                                        </li>
                                        
                                        <li>
                                            <Link to="#">Contact Us<i class="fa fa-chevron-down"></i></Link>
                                            <ul class="sub-menu right">
                                                <li><Link to="/contact-1">Contact us 1</Link></li>
                                                <li><Link to="/contact-2">Contact us 2</Link></li>
                                                <li><Link to="/contact-3">Contact us 3</Link></li>
                                                <li><Link to="/contact-4">Contact us 4</Link></li>
                                            </ul>
                                        </li>
                                    </ul>	
                                    <div class="dlab-social-icon">
                                        <ul>
                                            <li><Link class="site-button facebook sharp-sm fa fa-facebook" to="#"></Link></li>
                                            <li><Link class="site-button twitter sharp-sm fa fa-twitter" to="#"></Link></li>
                                            <li><Link class="site-button linkedin sharp-sm fa fa-linkedin" to="#"></Link></li>
                                            <li><Link class="site-button instagram sharp-sm fa fa-instagram" to="#"></Link></li>
                                        </ul>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </header> 
            </Sticky>               
            </>
        )
    }
}
export default ComponentDevHeader;