import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class SugerPlantMachinery extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Suger Plant Machinery Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Suger Plant Machinery Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Suger Plant Machinery Details:</h2>
                                        <br/>
                                        <h3>Product Details:</h3>
                                        <ul>
                                        <li>Automatic GradeAutomatic</li>
                                        <li>ComputerizedYes</li>
                                        <li>Product TypeMini Sugar Plants</li>
                                        <li>Voltage220 to 415 Volt (v)</li>
                                        <li>MaterialMS steel</li>
                                        <li>Delivery Time1 Months</li>
                                        <li>Main Domestic MarketAll India</li>
                                        <li>Supply Ability5 Per Month</li>
                                        <li>Offered By aditya impex</li>
                                        <li>Product Added OnSep 28, 2010</li>
                                        <li>Last Updated OnMay 18, 2019</li>
                                        <li>BrandMICROTECH ENGINEERS</li>
                                        </ul>
                                        <br/>
                                        <h3>Company Details:</h3>
                                        <p>Armed with highly qualified and experienced industry professionals, our company Microtech
                                        Engineers is world over recognized for its best manufacturing practices. We are counted amo
                                        ng top ranking manufacturers, exporters & suppliers specializing in products like SUGAR PL
                                        ANT (MINI), JAGGERY POWDER PLANT and JAGGERY PLANT. These products are wi
                                        dely chosen among numerous customers owing to their durability, sturdiness, and amazing pe
                                        rformance during usage. Founded in 2010, our company is a fast growing organization that al
                                        ways keeps customer satisfaction as its prime concern dur</p>
                                        <ul>
                                            <li>BUSINESS TYPEExporter, Manufacturer, Supplier</li>
                                        </ul>
                                       

                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/><br/><br/>
                                        <div class="row">

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img alt=""style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/sugar-plant.jpg")}/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                
            </>
        )
    }
}
export default SugerPlantMachinery;
