import React, { Component } from 'react';
import { animateScroll, Events } from 'react-scroll';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdFavorite from '@material-ui/icons/Favorite';



class ScrollToTop extends Component {

    state = {
        offset: this.props.offset || 1000,
        show: false,

    }

    componentDidMount = () => {

        window.addEventListener("scroll", () => {
            if (window.pageYOffset > this.state.offset) {
                this.setState({
                    ...this.state,
                    show: true
                })
            } else {
                this.setState({
                    ...this.state,
                    show: false
                })
            }
        })
    }

    scrollToTop = () => {
        animateScroll.scrollToTop();
    }
    render() {
        return (
            <>

                {this.state.show && (
                    <button className={`scroltop ${this.props.className}`} type="button"
                        onClick={this.scrollToTop}
                        style={{ display: "block" }}>
                        <i className="fa fa-arrow-up"></i>
                    </button>
                )}
            </>
        );
    }
}

export default ScrollToTop;