import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class SeedCleaningMachine extends Component {
    state = {
        isOpen: false,
        
    }
    
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Seed Cleaning Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Other Harvesting Products</li>
                                        <li>Seed Cleaning Machine Details</li>
                                 </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                    
                                        <h2 class="text-black font-weight-600 m-b15">Seed Cleaning Machine:</h2>
                                        <br/>
                                        
                                        
                                        
                                        
                                        <h3>Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                                
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Weight</td>
                                                    <td style={{ border:"1px black solid" }}>1700Kg</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Type</td>
                                                    <td style={{ border:"1px black solid" }}>Fully Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Material</td>
                                                    <td style={{ border:"1px black solid" }}>Mild Steel</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Power Source </td>
                                                    <td style={{ border:"1px black solid" }}>Electric</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Frequency</td>
                                                    <td style={{ border:"1px black solid" }}>50-60 Hz</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Phase</td>
                                                    <td style={{ border:"1px black solid" }}>3 Phase</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Voltage</td>
                                                    <td style={{ border:"1px black solid" }}>220-315 V</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Power </td>
                                                    <td style={{ border:"1px black solid" }}>5.5 HP</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Length </td>
                                                    <td style={{ border:"1px black solid" }}>14 feet</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Height </td>
                                                    <td style={{ border:"1px black solid" }}>12 feet</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Width </td>
                                                    <td style={{ border:"1px black solid" }}>8 feet</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Capacity </td>
                                                    <td style={{ border:"1px black solid" }}>1200 kg/hr</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br/>
                                        
                                        
                                        <p>We are considered to be one of the most professional manufacturers and
                                        suppliers of Multi-Purpose Seed Cleaning Machine. This type of cleaning
                                        machine is best suited for separating out the foreign particles from almost
                                        every type of grains. There are lots of models available in our wide gamut of
                                        Multi-purpose Big Cleaning Machine in different possible specifications. Our
                                        all the manufactured cleaning machines strictly conform to the industrial
                                        standards and norms. All these machines are excellent in durability, high in
                                        operational fluency and precise in construction.</p> 
                                        <br/><br/><br/>
                                        
                                    

                                    </div>

                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/>
                                    <br/><br/><br/><br/>
                                    <br/><br/><br/><br/>

                                    <div class="row">
                                    
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img alt="" style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/SEED CLEANING MACHINE.jpg")}/><br/><br/><br/><br/><br/>

            
                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/>
                                    <br/><br/>
                                    <div class="row">
                                    
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <img alt="" style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/multi pupose   grain cleaning machine.jpg")}/>
                                    <br/>
                                    </div>
                                    </div>
                                    </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                
                    
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>               
            </>
        )
    }
}
export default SeedCleaningMachine;
