import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class SolarHomeKit extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Solar Home Kit Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Non Renewable Energy</li>
                                        <li>Solar Home Kit Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Solar Home Kit Details:</h2>
                                      <br/>
                                      <img alt="" style={{height: 300, width: 300 }}src={require("../../images/Aditya impex Jpg/SOLAR HOME KIT.jpg")}/>
                                      <br/><br/><br/>

                                       <p>A 500 Watt off grid solar system is designed to give power supply for 4-5 hours to 2-
                                        3 BHK homes in India having severe power cuts and unreliable electricity supply. It is
                                        a Small Power plant which can run a Peak Load up to 650 Watts.
                                        If you are living in a City or where Electricity is available and Power cuts are
                                        frequent, The Solar Combo helps you to save Electricity of almost 1.5 Units per day
                                        which is used in battery charging.</p>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Main Products:</h3>
                                        <br/>
                                        <ul>
                                            <li>Inverter - 1100 VA (1 nos.)</li>
                                            <li>Battery - 150 Ah (1 nos.)</li>
                                            <li>Solar Panels - 540 Watt (180 watt * 3 nos.)</li>
                                        </ul>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Subsidy:</h3>
                                        <ul>
                                            <li>Government of India does not offer any subsidy on off grid solar systems.</li>
                                        </ul>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15"> Running Load:</h3>
                                        <br/>
                                        <ul>
                                        <li>Up to 650 watts of power with combination of - 2 Ceiling Fans, 10 Led lights, 1
                                        Television + 1 Laptop/Mobile Charging.</li>
                                        </ul>
                                        <br/>
                                        





                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/>
                                        <div class="row">
                                        
                                        <h3 class="text-black font-weight-600 m-b15">Backup Time:</h3><br/>
                                        <br/><br/>
                                        <div>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Load</th>
                                                    <td style={{ border:"1px black solid" }}>500 W</td>
                                                    <td style={{ border:"1px black solid" }}>400 W</td>
                                                    <td style={{ border:"1px black solid" }}>300 W</td>
                                                    <td style={{ border:"1px black solid" }}>200 W</td>
                                                    <td style={{ border:"1px black solid" }}>100 W</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Duration</th>
                                                    <td style={{ border:"1px black solid" }}>2 hrs 10 mint</td>
                                                    <td style={{ border:"1px black solid" }}>3 hrs</td>
                                                    <td style={{ border:"1px black solid" }}>4 hrs 30 mints</td>
                                                    <td style={{ border:"1px black solid" }}>7 hrs 30 mints</td>
                                                    <td style={{ border:"1px black solid" }}>18 hrs 30 mint</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                            </tbody>
                                        </table>
                                        </div>
                                        <div>
                                        <br/><br/><br/><br/><br/><br/>

                                        <h3 class="text-black font-weight-600 m-b15">Technical Details:</h3>
                                        <br/>

                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                               
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Inverter Model</td>
                                                    <td style={{ border:"1px black solid" }}>NXG 1100</td>
                                               </tr>
                                               <tr>
                                                    <td style={{ border:"1px black solid" }}>Inverter Capacity </td>
                                                    <td style={{ border:"1px black solid" }}>1100 VA </td>
                                               </tr>
                                               <tr>
                                                    <td style={{ border:"1px black solid" }}>Efficiency </td>
                                                    <td style={{ border:"1px black solid" }}>Inverter - 97%, Module 16% </td>
                                               </tr>
                                               <tr>
                                                    <td style={{ border:"1px black solid" }}> Module Type</td>
                                                    <td style={{ border:"1px black solid" }}> Mono-Crystalline</td>
                                               </tr>
                                               <tr>
                                                    <td style={{ border:"1px black solid" }}>Battery Included </td>
                                                    <td style={{ border:"1px black solid" }}>Yes </td>
                                               </tr>
                                               <tr>
                                                    <td style={{ border:"1px black solid" }}>Standard Installation Included </td>
                                                    <td style={{ border:"1px black solid" }}> No</td>
                                               </tr>
                                               <tr>
                                                    <td style={{ border:"1px black solid" }}>Installation Package </td>
                                                    <td style={{ border:"1px black solid" }}> Panel stand, 10 meter wire along
                                                    with connectors, excluding fixing
                                                    and wiring.</td>
                                               </tr>
                                                
                                            </tbody>
                                        </table>
                                        </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>            
            </>
        )
    }
}
export default SolarHomeKit;
