import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class VegFruitCuttingDetails extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Veg & Fruit Cutting Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Food Processing Machinery</li>
                                        <li>Veg & Fruit Cutting Machine Details</li>

                                    </ul>

                                    <br/><br/>

                                    

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Veg & Fruit Cutting Machine Details:</h2>
                                        <br/><br/>
                                        <img alt="" style={{height: 400, width: 400 }}src={require("../../images/Aditya impex Jpg/vegetable and fruit cutting machine.jpg")}/>
                                        <br/><br/><br/>
                                        <h3>Technical Specifications:</h3>
                                        <br/><br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>SKU:</th>
                                                    <td style={{ border:"1px black solid" }}>GE779HL0FT17KNAFAMZ</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Product Line: </th>
                                                    <td style={{ border:"1px black solid" }}>Acha</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Weight (kg):</th>
                                                    <td style={{ border:"1px black solid" }}>60</td>
                                                </tr>
                                                
                                                
                                            </thead>
                                            <tbody>
                                                
                                            </tbody>
                                        </table>
                                        <p>In the school refectory, restaurant, hotel and food processing plant, cutting vegetables
                                        undoubtedly is a kind of heavy work. And the vegetable cutting machine can ease this work,
                                        because it is widely used to cutting root, stem, leaf vegetables, kelp and bean products into
                                        various standard sizes.Large Capacity Vegetable Cutting Machine Features▷Centrifugal slicing
                                        system is to slice potatoes, carrots, ginger, etc. these hard vegetables.▷Vertical blade is to
                                        processing leafy-soft vegetables and vegetable-slices into different standard strips, cubes,
                                        rhombuses.▷Slice thickness and cutting length can be adjusted in the regular range▷The
                                        processed vegetables have smooth surface, standard shape, intact texture and fresh
                                        flavor▷Easy to operate, simple to maintain- Material: Stainless Steel- size :
                                        860mm*430mm*800mm- Voltage : 220v- Power : 750 Watts- Frequency : 50hz- Net weight :
                                        85kg- production : 100- 300kg/hr- Barrel Diameter : 200mm</p> 
                                        <br/><br/>

                                       



                                    </div>
                                    <div class="col-lg-6">
                                    
                                        <div class="row">
                                        <h2 class="text-black font-weight-600 m-b15">Vegetable And Fruit Packing Machine:</h2>
                                        <img alt=""style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/vegetable and fruit packing machine.png")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                
            </>
        )
    }
}
export default VegFruitCuttingDetails;