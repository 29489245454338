import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class ScutionHosePipeDetails extends Component {
    state = {
        isOpen: false,
        
    }
    
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Suction & Delivery Hose Pipe Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Agriculture Equipments</li>
                                        <li>Suction & Delivery Hose Pipe Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Suction & Delivery Hose Pipe Details</h2>
                                        <br />
                                        <img alt=""style={{height: 300, width: 300 }} src={require("../../images/Aditya impex Jpg/pvc-flat-hose-pipe-500x500.jpg")}/>
                                        <br/><br/><br/>

                                        <h3>Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                               
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Color</td>
                                                    <td style={{ border:"1px black solid" }}>Blue</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Size</td>
                                                    <td style={{ border:"1px black solid" }}>1 inch, 3 inch, 2 inch</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Single Hose Length</td>
                                                    <td style={{ border:"1px black solid" }}>20m-30m</td>
                                                </tr>
                                              </tbody>
                                        </table>
                                        <br/>
                                        <p>PVC Flat Hose is a superior replacement to conventional cotton/kisan/Lapeta
                                                hose pipes. It is the first and only product made in India of this kind.</p>
                                                <br/>
                                                <p>Composition : The hose is composed of flexible PVC with synthetic yarn
                                                reinforcement to provide extra strength.</p>

                                                <br/><br/><br/>
                                       



                                    </div>
                                    <div class="col-lg-6">
                                    

                                        <h2 class="text-black font-weight-600 m-b15">Hose Pipe</h2>
                                        <br/><br/>
                                        <img alt=""style={{height: 300, width: 300 }} src={require("../../images/Aditya impex Jpg/pipe.jpg")}/>
                                        <br/><br/><br/><br/>

                                        <h3>Product Details:</h3>

                                        <br/>

                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                               
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Single Hose Length</td>
                                                    <td style={{ border:"1px black solid" }}>12m-20m, 20m-30m</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Size</td>
                                                    <td style={{ border:"1px black solid" }}>3 inch, 2 inch, 1 inch</td>
                                                </tr>
                                                
                                              </tbody>
                                        </table>
                                        <br/>
                                        <p>Ideal for Light Duty collection conveying of Air, Gases, Dust and Light
                                        materials. Used for conduits of air, industrial ventilation, collecting dust with
                                        exhaust fans in stone masoning, fiberglass, textiles, asbestos processing and
                                        other industries, cooling of motors and air intakes for all machineries,
                                        conveying warm air for drying, vacuum cleaning equipments, air-conditions for
                                        offices, kitchen-range overhead exhaust, etc.</p>
                                            <div class="col-lg-12 m-b30">
                                                {/* <img alt="" src={require("../../images/portfolio/AFRIDEV.jpg")}/> */}
                                            </div>
                                            <div class="col-lg-12 m-b30">
                                                {/* <img alt="" src={require("../../images/portfolio/HAND DEEPWELL SPARE PARTS.jpg")}/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>             
            </>
        )
    }
}
export default ScutionHosePipeDetails;