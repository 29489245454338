import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class Generator extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Generator Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Generator Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        
                                        <h2 class="text-black font-weight-600 m-b15">Generator:</h2>
                                        <br/>
                                        <h2 class="text-black font-weight-600 m-b15">Diesel Generator:</h2>
                                        <br/>
                                        <img alt="" style={{height: 450, width: 450 }}src={require("../../images/Aditya impex Jpg/DIESEL GENERATOR copy.jpg")}/>
                                        <br/><br/><br/>
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Set</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Cooling System</td>
                                                    <td style={{ border:"1px black solid" }}>Air Cooling,Water Cooling</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Noise Level</td>
                                                    <td style={{ border:"1px black solid" }}>Silent or Soundproof</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Genset Rating KVA</td>
                                                    <td style={{ border:"1px black solid" }}>2.2 kva</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Power</td>
                                                    <td style={{ border:"1px black solid" }}>Vspower</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Engine Power</td>
                                                    <td style={{ border:"1px black solid" }}>5 hp</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Fuel Type</td>
                                                    <td style={{ border:"1px black solid" }}>Diesel</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Number of Cylinder</td>
                                                    <td style={{ border:"1px black solid" }}>1</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Output Type</td>
                                                    <td style={{ border:"1px black solid" }}>AC Single Phase</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Rated Speed</td>
                                                    <td style={{ border:"1px black solid" }}>1500</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Engine Model </td>
                                                    <td style={{ border:"1px black solid" }}>vspower 006</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Fuel Tank Capacity</td>
                                                    <td style={{ border:"1px black solid" }}>4 liter</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>No. of Phase</td>
                                                    <td style={{ border:"1px black solid" }}>1 single</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Phase</td>
                                                    <td style={{ border:"1px black solid" }}>1</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Output Voltage </td>
                                                    <td style={{ border:"1px black solid" }}>240</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Bore & Stroke Size</td>
                                                    <td style={{ border:"1px black solid" }}>85 mm</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Alternator</td>
                                                    <td style={{ border:"1px black solid" }}>vspower</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Warranty</td>
                                                    <td style={{ border:"1px black solid" }}>1 year</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Amps</td>
                                                    <td style={{ border:"1px black solid" }}>12</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Condition</td>
                                                    <td style={{ border:"1px black solid" }}>New</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br/>
                                        <ul>
                                            <li>2.2 kva singal air cool gen set</li>
                                        </ul>
                                        <br/><br/>






                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/>
                                        <div class="row">
                                        <div>
                                        <h2 class="text-black font-weight-600 m-b15">Silent Generator:</h2>
                                        </div>
                                        <br/>
                                        <div>
                                        <img alt="" style={{height: 450 , width: 450 }}src={require("../../images/Aditya impex Jpg/GNERATOR SET SILENT copy.jpg")}/>
                                        </div>
                                        <div>
                                        <br/><br/><br/>
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        </div>
                                        <br/>
                                        <div><br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Unit</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Power</td>
                                                    <td style={{ border:"1px black solid" }}>10</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Voltage</td>
                                                    <td style={{ border:"1px black solid" }}>415 V</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Phase</td>
                                                    <td style={{ border:"1px black solid" }}>Three</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage/Application</td>
                                                    <td style={{ border:"1px black solid" }}>Construction</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Fuel type</td>
                                                    <td style={{ border:"1px black solid" }}>Diesel</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Type</td>
                                                    <td style={{ border:"1px black solid" }}>Automatic, Semi-automatic, Manual</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Frequency</td>
                                                    <td style={{ border:"1px black solid" }}>50 Hz</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Rated Power</td>
                                                    <td style={{ border:"1px black solid" }}>24kW / 30kva</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Speed</td>
                                                    <td style={{ border:"1px black solid" }}>1500 r/min</td>
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                        </div>
                                        <br/>
                                        <p>We are offering a wide range of Silent Generator.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                
            </>
        )
    }
}
export default Generator;
