import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class ColdStorage extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader />
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Cold Storage Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Cold Storage Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">

                                        <h2 class="text-black font-weight-600 m-b15">Cold Storage:</h2>
                                        <br />




                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br />
                                        <p>Cold storage industry is a very important and essential industry. The
                                        temperature is maintained low with the help of precision instruments. Cold
                                        storage is used to preserve fruits and vegetables. Once they are kept in the
                                        cold storage, they do not get spoiled even after many months. This item,
                                        when needed, can be taken from the cold storage and can be made
                                        available to consumers very easily. Cold storage is a vital link between the
                                        production and consumption of perishable products. Apart from the
                                        conservation of perishables, the cold storage also help in increasing the
                                        marketing period of these commodities and ensure availability to the
                                        consumer over a long period. It is also clear that the cold storage facility
                                        available in various states of the country reveals wide variation. A new
                                        entrepreneur can well venture into this field by installing a project of cold
                                        storage to save the fruits and vegetables from wastage.</p>
                                        <br />

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                        <div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img alt="" style={{ height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/COLD STORAGE PHOTOS.jpg")} />

                                            <br />

                                            <img alt="" style={{ height: 450, width: 450 }} src={require("../../images/Aditya impex Jpg/cold-storage-2.png")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>  
            </>
        )
    }
}
export default ColdStorage;
