import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'

const bg = require('../../images/banner/bnr4.jpg')

class MiniOilMillPlant extends Component {

    
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Mini Oil Mill Plant Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Mini Oil Mill Plant Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        
                                        <h2 class="text-black font-weight-600 m-b15">Cold Press Oil Machine:</h2>
                                        <br/>

                                        <img alt="" style={{height: 500, width: 500 }}src={require("../../images/Aditya impex Jpg/cold press-processing-machine-500x500.jpg")}/>
                                        <br/>
                                        
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Set</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automation Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Automatic, Semi-Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Capacity</td>
                                                    <td style={{ border:"1px black solid" }}>1-5 ton/day, 5-20 ton/day</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage</td>
                                                    <td style={{ border:"1px black solid" }}>Sunflower Oil, Palm Fruit Oil, Cotton Seed Oil, Peanut Oil</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Condition</td>
                                                    <td style={{ border:"1px black solid" }}>New</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Voltage (V)</td>
                                                    <td style={{ border:"1px black solid" }}>440 volt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Power Consumption</td>
                                                    <td style={{ border:"1px black solid" }}>3 Phase 2 HP</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Motor</td>
                                                    <td style={{ border:"1px black solid" }}>1440 rpm</td>
                                                </tr>

                                                
                                            </tbody>
                                        </table>
                                        <br/>
                                        <p>These Oil Mills uses Technique of Cold press. Mostly Manufactued in South
                                        India, we are Supplier of these Machines in Eastern india.
                                        Available in Different Capacities and Sizes.</p>
                                        <br/>
                                        <br/>
                                        <h2 class="text-black font-weight-600 m-b15">Groundnuts Oil Machine:</h2>
                                        <br/>
                                        <img alt="" style={{height: 500, width: 500 }}src={require("../../images/Aditya impex Jpg/groundnutsl-milling-machine-500x500.jpg")}/>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Set</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automation Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Automatic, Semi-Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automatic Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Automatic, Semi-Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Capacity</td>
                                                    <td style={{ border:"1px black solid" }}>10 Ton / 24 Hour, 1-5 ton/day, 60-100 ton/day, 5-20 ton/day, 20-60 ton/day</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage</td>
                                                    <td style={{ border:"1px black solid" }}>Palm Fruit Oil, Peanut Oil, Soybean Oil, Sunflower Oil, MUSTARD OIL</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Power</td>
                                                    <td style={{ border:"1px black solid" }}>10-15</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Condition</td>
                                                    <td style={{ border:"1px black solid" }}>Yes</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>High Tensile Strength</td>
                                                    <td style={{ border:"1px black solid" }}>Yes</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage/Application</td>
                                                    <td style={{ border:"1px black solid" }}>Peanut Oil</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Driven Type</td>
                                                    <td style={{ border:"1px black solid" }}>MOTOR & PULLEY</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Power Consumption</td>
                                                    <td style={{ border:"1px black solid" }}>3 Phase 30 HP Motor</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Chamber</td>
                                                    <td style={{ border:"1px black solid" }}>Fabricated chamber</td>
                                                </tr>

                                                
                                            </tbody>
                                        </table>
                                        <br/>
                                        <p>These Expellers machines are used for extraction of Oils From Seeds and
                                        Grains Such as Mustard Seeds, Sesame Seeds, Groundnuts, Peanuts, etc.</p>
                                        <br/><br/>
                                        <h2 class="text-black font-weight-600 m-b15">Mustard Oil Mill Machine:</h2>
                                        <br/>
                                        <img alt="" style={{height: 500, width: 500 }}src={require("../../images/Aditya impex Jpg/mustard-oil-mill-500x500.jpg")}/>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Set</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automation Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Semi-Automatic, Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Design</td>
                                                    <td style={{ border:"1px black solid" }}>Standard</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage</td>
                                                    <td style={{ border:"1px black solid" }}>Peanut Oil, Palm Fruit Oil, Sunflower Oil, Cotton Seed Oil, Soybean Oil</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Capacity</td>
                                                    <td style={{ border:"1px black solid" }}>1-5 ton/day, 5-20 ton/day, 20-60 ton/day, 60-100 ton/day, 100-200 ton/day, 80kg per hour - 5ton per hour</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Material</td>
                                                    <td style={{ border:"1px black solid" }}>M.S & C.I</td>
                                                </tr>
                                                
                                                
                                            </tbody>
                                        </table>
                                        <br/>
                                        <p>The Mustard Oil Mill Machine is known for cultivation of oil seeds and
                                        extraction of oil from them form and important part of Indian economy.
                                        Expellers are most commonly used machines for oil extraction and are more
                                        economical than Kolhus and Ghanies etc.</p>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Features:</h3>
                                        <br/>
                                        <ul>
                                            <li>Accurate dimensions</li>
                                            <li>Longer service life</li>
                                            <li>Efficient working</li>
                                        </ul>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Specifications:</h3>
                                        <br/>
                                        <ul>
                                            <li>Chamber Size: 610 x 112mm</li>
                                            <li>Capacity (Per Hour): 70 kg</li>
                                            <li>Power Required (in B.H.P): 10H.P</li>
                                            <li>Approximate Weight: 950 Kg.</li>
                                            <li>Space required (For Fitting): 3 x 5 Meter</li>
                                            <li>R.P.M. of main shaft: 35</li>
                                            <li>R.P.M. of pulley: 175</li>
                                            <li>Dia. of pulley: 56 cm</li>
                                            <li>Face of Pulley: 10 cm</li>
                                        </ul>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Measurement:</h3>
                                        <br/>
                                        <ul>
                                            <li>Length: 2 Meter</li>
                                            <li>Breadth: 1 Meter</li>
                                            <li>Height: 1.5 Meter</li>
                                        </ul>
                                        <br/><br/>

                                        

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                        <h2 class="text-black font-weight-600 m-b15">Oil Filter Press Machine:</h2>
                                        <br/>
                                        <img alt="" style={{height: 500, width: 500 }}src={require("../../images/Aditya impex Jpg/oil-filter-press-machine-500x500.jpg")}/>
                                        <br/>
                                        <div><br/>
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        

                                        <br/>
                                        
                                        
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Unit</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Condition</td>
                                                    <td style={{ border:"1px black solid" }}>New</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automatic Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Semi-Automatic,Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Design</td>
                                                    <td style={{ border:"1px black solid" }}>Standard</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Capacity</td>
                                                    <td style={{ border:"1px black solid" }}>20kg Per Hour - 2 Ton Per Hour</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Voltage (V)</td>
                                                    <td style={{ border:"1px black solid" }}>220 Volt/ 440 Volt</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        <br/>
                                        <p>Unmatchable quality of Oil Filter Press Machine is offer by our company.
                                        This Oil Filter Press Machine is known for its high durable nature and high
                                        performance. Oil Filter Press Machine is extensively used for transfer of oil
                                        from storage tank to filter press.</p>
                                        <br/>
                                        <div class="col-lg-6">
                                        <br/><br/>
                                        <h3 class="text-black font-weight-600 m-b15">Features:</h3>
                                        
                                        <ul>
                                            <li>Low maintenance</li>
                                            <li>Efficient working</li>
                                            <li>Highly demanded</li>
                                        </ul>
                                        </div>
                                        <br/>
                                        <div>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Specifications:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}><center>Particulars in mm</center></th>
                                                    <th style={{ border:"1px black solid" }}><center>Particulars in inches</center></th>
                                                    <th style={{ border:"1px black solid" }}><center>No. of Plates</center></th>
                                                    <th style={{ border:"1px black solid" }}><center>Type</center></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>305x305</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>12 x 12</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>12</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>355x355</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>14 x 14</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>14</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>406x406</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>16 x 16</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>16</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>457x457</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>18 x18</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>18</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>508x508</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>20 x 20</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>20</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>610x610</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>24 x 24</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>24</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>610x610</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>24 x 24</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>30</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>762x762</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>30 x 30</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>30</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>915x915</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>36 x 36</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>36</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>Recess</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>610x610</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>24 x 24</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>24</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>23 Frame</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>762x762</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>30 x 30</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>30</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>29 Frame</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>915x915</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>36 x 36</center></td>
                                                    <td style={{ border:"1px black solid" }}><cenetr>36</cenetr></td>
                                                    <td style={{ border:"1px black solid" }}><center>35 Frame</center></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div>
                                        <br/><br/>
                                        <h2 class="text-black font-weight-600 m-b15">Oil Ghani Machine:</h2>
                                        <br/>
                                        <img alt="" style={{height: 500, width: 500 }}src={require("../../images/Aditya impex Jpg/oil-ghani-machine-500x500.png")}/>
                                        
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Pair</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automation Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Capacity</td>
                                                    <td style={{ border:"1px black solid" }}>20-60 ton/day</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage/Application</td>
                                                    <td style={{ border:"1px black solid" }}>Mustard Oil</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Power</td>
                                                    <td style={{ border:"1px black solid" }}>240 V</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Design</td>
                                                    <td style={{ border:"1px black solid" }}>Standard</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage</td>
                                                    <td style={{ border:"1px black solid" }}>Peanut Oil,Sunflower Oil,Cotton Seed Oil,Palm Fruit Oil</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Number Of Phases</td>
                                                    <td style={{ border:"1px black solid" }}>Single Phase</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Motor</td>
                                                    <td style={{ border:"1px black solid" }}>2 HP</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>I deal in</td>
                                                    <td style={{ border:"1px black solid" }}>New Only</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Material</td>
                                                    <td style={{ border:"1px black solid" }}>C.I</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Bearings</td>
                                                    <td style={{ border:"1px black solid" }}>Roller Bearings</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Consumption Hp</td>
                                                    <td style={{ border:"1px black solid" }}>2HP</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        <br/>
                                        <p>The Oil Ghani Machine is used to extract oil from oil seeds like Mustard,
                                        Sesame, Sunflower, etc. A ghani consists of a large pestle and mortar rotated
                                        by Electric Motor. Seed is fed slowly into the mortar and the pressure exerted
                                        by the pestle breaks the cells and releases the oil. Ghani technology is mainly
                                        restricted to the Indian sub-continent. The demand of Mustard Oil is increasing
                                        with the time. Refined Ghani, filtered, double filtered mustard oil have given
                                        new thrust to its market. Due to consumption in household and in pickle
                                        industries it appears to be good scope for establishing mustard oil industry.
                                        </p>
                                        <br/>
                                        <div class="col-lg-6">
                                        <h3 class="text-black font-weight-600 m-b15">Features:</h3>
                                        <br/>
                                        <ul>
                                            <li>Highly efficient</li>
                                            <li>Quality product</li>
                                            <li>Highly reliable</li>
                                        </ul>
                                        </div>

                                        <br/>
                                        <div class="col-lg-12">
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Additional Information:</h3>
                                        <br/>
                                        <ul>
                                            <li>Item Code: 49</li>
                                            <li>Production Capacity: 40Kg Per hour</li>
                                            <li>Delivery Time: 7- 10 Days</li>
                                            <li>Packaging Details: LOOSE</li>
                                        </ul>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter/>                
            </>
        )
    }
}
export default MiniOilMillPlant;
