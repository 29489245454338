import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';



class ConstructionHeader extends Component {


    componentDidMount() {

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }

    }
    
    render() {
        return (
            <>
            <header class="site-header mo-left header-transparent box-header header">
                <div class="top-bar text-black">
                    <div class="container">
                        <div class="row d-flex justify-content-between">
                            <div class="dlab-topbar-left">
                                <ul>
                                    <li><Link to="/about-1">About Us</Link></li>
                                    <li><Link to="/privacy-policy">Refund Policy</Link></li>
                                    <li><Link to="/help-desk">Help Desk</Link></li>
                                </ul>
                            </div>
                            <div class="dlab-topbar-right topbar-social">
                                <ul>
                                    <li><Link to="/#" class="site-button-link facebook hover"><i class="fa fa-facebook"></i></Link></li>
                                    <li><Link to="/#" class="site-button-link google-plus hover"><i class="fa fa-google-plus"></i></Link></li>
                                    <li><Link to="/#" class="site-button-link twitter hover"><i class="fa fa-twitter"></i></Link></li>
                                    <li><Link to="/#" class="site-button-link instagram hover"><i class="fa fa-instagram"></i></Link></li>
                                    <li><Link to="/#" class="site-button-link linkedin hover"><i class="fa fa-linkedin"></i></Link></li>
                                    <li><Link to="/#" class="site-button-link youtube hover"><i class="fa fa-youtube-play"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Sticky innerZ={999} enabled={true}>
                    <div class="sticky-header main-bar-wraper navbar-expand-lg">
                        <div class="main-bar clearfix ">
                            <div class="container clearfix">
                                <div class="logo-header mostion">
                                    <Link to="/"><img src={require("../../images/aditya_logo.png")} alt=""/></Link>
                                </div>
                                
                                <button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                
                                <div class="extra-nav">
                                    <Link to="/contact-1" class="site-button radius-no">GET A QUOTE</Link>
                                </div>
                                
                                <div class="dlab-quik-search ">
                                    <form action="#">
                                        <input name="search" value="" type="text" class="form-control" placeholder="Type to search"/>
                                        <span id="quik-search-remove"><i class="ti-close"></i></span>
                                    </form>
                                </div>
                                
                                <div class="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                    <div class="logo-header d-md-block d-lg-none">
                                        <Link to="/"><img src={require("../../images/aditya_logo.png")} alt=""/></Link>
                                    </div>
                                    <ul class="nav navbar-nav">	
                                        <li class="active has-mega-menu homedemo"> <Link to="#">Home<i class="fa fa-chevron-down"></i></Link>
                                            <ul class="mega-menu">
                                                <li><Link to={'/construction'}><img src={require('../../demo/pic5.jpg')} alt="" /><span>default - Construction Machinery</span></Link></li>
                                               <li><Link to="/agriculture"><img src={require("../../demo/pic8.jpg")} alt=""/><span>Home - Agriculture</span></Link></li>
                                                <li><Link to={'/industry'}><img src={require('../../demo/pic1.jpg')} alt="" /><span>Home - Industry</span></Link></li>
                                                <li><Link to={'/workshop'}><img src={require('../../demo/pic14.jpg')} alt="" /><span>Home - Workshop Machinery</span></Link></li>
                                                <li><Link to={'/componentDevelopment'}><img src={require('../../demo/pic3.jpg')} alt="" /><span>Home - Precision Component Development</span></Link></li>
                                                <li><Link to={'/nonRenewableEnergy'}><img src={require('../../demo/pic6.jpg')} alt="" /><span>Home - Non Renewable Energy</span></Link></li>
                                                <li><Link to={'/tender'}><img src={require('../../demo/pic3.jpg')} alt="" /><span>Home - Project To Tender Division</span></Link></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="#">About us</Link>
                                            
                                        </li>
                                        <li class=""> <Link to="#">Portfolio<i class="fa fa-chevron-down"></i></Link>
                                            <ul class="sub-menu">
                                                <li><Link to={'/industry'}><span> Industry</span></Link></li>
                                                <li><Link to={'/agriculture'}><span> Agriculture Equitment</span></Link></li>
                                                <li><Link to={'/construction'}><span> Construction Machinery</span></Link></li>
                                                <li><Link to={'/workshop'}><span> Workshop Machinery</span></Link></li>
                                                <li><Link to={'/componentDevelopment'}><span> Precision Component Development</span></Link></li>
                                                <li><Link to={'/nonRenewableEnergy'}><span> Non Renewable Energy</span></Link></li>
                                                <li><Link to={'/tender'}><span> Project To Tender Division</span></Link></li>
                                            </ul>
                                        </li>
                                       
                                        
                                        <li>
                                            <Link to="#">Contact Us<i class="fa fa-chevron-down"></i></Link>
                                            <ul class="sub-menu">
                                                <li><Link to="/contact-1">Contact us 1</Link></li>
                                                <li><Link to="/contact-2">Contact us 2</Link></li>
                                                <li><Link to="/contact-3">Contact us 3</Link></li>
                                                <li><Link to="/contact-4">Contact us 4</Link></li>
                                            </ul>
                                        </li>
                                    </ul>	
                                    <div class="dlab-social-icon">
                                        <ul>
                                            <li><Link class="site-button facebook circle-sm outline fa fa-facebook" to="/javascript:void(0);"></Link></li>
                                            <li><Link class="site-button twitter circle-sm outline fa fa-twitter" to="/javascript:void(0);"></Link></li>
                                            <li><Link class="site-button linkedin circle-sm outline fa fa-linkedin" to="/javascript:void(0);"></Link></li>
                                            <li><Link class="site-button instagram circle-sm outline fa fa-instagram" to="/javascript:void(0);"></Link></li>
                                        </ul>
                                    </div>		
                                </div>
                            </div>
                        </div>
                    </div>
                </Sticky>
            </header>                
            </>
        )
    }
}
export default ConstructionHeader;