import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class GroundnutsOilMachine extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader />
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Groundnuts Oil Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Groundnuts Oil Machine Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">

                                        <h2 class="text-black font-weight-600 m-b15">Groundnuts Oil Machine:</h2>
                                        <br />
                                        {/* <img alt="" style={{ height: 500, width: 500 }} src={require("../../images/Aditya impex Jpg/groundnutsl-milling-machine-500x500.jpg")} /> */}

                                        <br />
                                        <div>

                                            <br />

                                            <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>


                                            <br />

                                            <table style={{ border: "1px black solid" }}>
                                                <thead>
                                                    <tr>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Minimum Order Quantity</td>
                                                        <td style={{ border: "1px black solid" }}>1 Set</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Automation Grade</td>
                                                        <td style={{ border: "1px black solid" }}>Automatic, Semi-Automatic</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Automatic Grade</td>
                                                        <td style={{ border: "1px black solid" }}>Automatic, Semi-Automatic</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Capacity</td>
                                                        <td style={{ border: "1px black solid" }}>10 Ton / 24 Hour, 1-5 ton/day, 60-100 ton/day, 5-20 ton/day, 20-60 ton/day</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Usage</td>
                                                        <td style={{ border: "1px black solid" }}>Palm Fruit Oil, Peanut Oil, Soybean Oil, Sunflower Oil, MUSTARD OIL</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Brand</td>
                                                        <td style={{ border: "1px black solid" }}>Aditya</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Power</td>
                                                        <td style={{ border: "1px black solid" }}>10-15</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Condition</td>
                                                        <td style={{ border: "1px black solid" }}>Yes</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>High Tensile Strength</td>
                                                        <td style={{ border: "1px black solid" }}>Yes</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Usage/Application</td>
                                                        <td style={{ border: "1px black solid" }}>Peanut Oil</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Driven Type</td>
                                                        <td style={{ border: "1px black solid" }}>MOTOR & PULLEY</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Power Consumption</td>
                                                        <td style={{ border: "1px black solid" }}>3 Phase 30 HP Motor</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px black solid" }}>Chamber</td>
                                                        <td style={{ border: "1px black solid" }}>Fabricated chamber</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                            <br />
                                            <p>These Expellers machines are used for extraction of Oils From Seeds and
                                        Grains Such as Mustard Seeds, Sesame Seeds, Groundnuts, Peanuts, etc.</p>
                                        </div>
                              

                                    </div>
                                    <div class="col-lg-6">
                            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                            <div class="row">
                            <img alt="" style={{ height: 500, width: 500 }} src={require("../../images/Aditya impex Jpg/groundnutsl-milling-machine-500x500.jpg")} />


                            </div>
                        </div>
                                </div>


                            </div>

                        </div>
                        
                    </div>

                </div>


                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>
            </>
        )
    }
}
export default GroundnutsOilMachine;
