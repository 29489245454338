import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class WheatCleaningMachine extends Component {
    state = {
        isOpen: false,
        
    }
    
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Wheat Cleaning Machine Details:</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Other Harvesting Products</li>
                                        <li>Wheat Cleaning Machine Details:</li>
                                 </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                    
                                        <h2 class="text-black font-weight-600 m-b15">Wheat Cleaning Machine:</h2>
                                        <br/><br/>
                                        <img alt="" style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/SEED CLEANING MACHINE PHOTO.jpg")}/>
                                        <br/><br/><br/>

                                        
                                        <h3>Product Details:</h3>
                                        
                                        <p>We are master in presenting a wide range of customized and quality
                                        assured Wheat Cleaning Machine. Less maintenance is required in our
                                        Wheat Cleaning Machine. Also, we manufacture this Machines by using
                                        cutting edge technology and qualitative components.</p>
                                        <br/>

                                        <h3>Features:</h3>
                                        <ul>
                                            <li>Easy installation</li>
                                            <li>Efficient working</li>
                                            <li>Light weight</li>
                                        </ul>

                                        
                                    </div>

                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/>
                                        <div class="row">
                                        <table style={{ border:"1px black solid" }}>
                                                
                                                <tbody>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                        <td style={{ border:"1px black solid" }}>1 Piece</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Motor Power</td>
                                                        <td style={{ border:"1px black solid" }}>1.5-6 HP</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Use</td>
                                                        <td style={{ border:"1px black solid" }}>Commercial</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Type </td>
                                                        <td style={{ border:"1px black solid" }}>Semi-Automatic,Fully Automatic</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Capacity</td>
                                                        <td style={{ border:"1px black solid" }}>100-200 (kg/hr)</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Brand</td>
                                                        <td style={{ border:"1px black solid" }}>Aditya</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Operation Mode</td>
                                                        <td style={{ border:"1px black solid" }}>Automatic</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Capacity ( ton per day)</td>
                                                        <td style={{ border:"1px black solid" }}>40 to 100,20 to 39</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Power Rating</td>
                                                        <td style={{ border:"1px black solid" }}>11-15 HP,1-5 HP,6-10 HP</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Condition</td>
                                                        <td style={{ border:"1px black solid" }}>New</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Voltage (V)</td>
                                                        <td style={{ border:"1px black solid" }}>440volt</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Automation Grade</td>
                                                        <td style={{ border:"1px black solid" }}>Automatic</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border:"1px black solid" }}>Color</td>
                                                        <td style={{ border:"1px black solid" }}>Metal</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                
                    
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>            
            </>
        )
    }
}
export default WheatCleaningMachine;
