import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class TurbinWaterPumpsDetails extends Component {

    
    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Turbine Pumps Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Agriculture Equipments</li>
                                        <li>Turbine Pumps Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Turbine Pumps Information</h2>
                                        <p>
                                        While we are appreciated in the industry for offering cost-effective Vertical Water Turbine Pumps, we are counted amongst the major manufacturer, exporter and supplier of pumps, and we only offer reliable products. While our range of Vertical Water Turbine Pump promises low maintenance cost, high performance, and optimum functionality, we package the pumps using premium packaging materials and ensure safe delivery. We also accept bulk orders by the clients. We are based in Gujarat, India.
                                        </p>
                                        <ul>
                                            <li>Typical Applications available - Semi-crossed, flat belt driven Pump- by mens of a slow speed vertical Diesel engine.</li>
                                            <li>V-belt driven Pump - powered by an electric motorlBoth motor and pump are mounted on a fabricated steel base. Pump fitted with right angle gear drive - powered by a multi-cylinder medium speed Diesel engine.</li>
                                            <li>Alternatively it could be driven by an electric motor. A further semi-crossed, flat belt driven Pump operating on an open well-driven by a slow speed horizontal Diesel engine. (A semicrossed V-belt could replace the flat belt).</li>
                                            <li>We are manufacturing 3" / 3.5" / 4.5" Vertical Turbine Pumps.</li>
                                            
                                        </ul>

                                        
                                        
                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/>
                                        <div class="row">
                                            <div class="col-lg-12 m-b30">
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img alt="" style={{ height: 400, width: 400 }} src={require("../../images/TURBIN WATER PUMPS.jpg")}/>
                                            </div>
                                            <div class="col-lg-12 m-b30">
                                                {/* <img alt="" src={require("../../images/portfolio/HAND DEEPWELL SPARE PARTS.jpg")}/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                  
            </>
        )
    }
}
export default TurbinWaterPumpsDetails;