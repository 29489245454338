import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'


import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';


import '../../css/plugins.css';
import '../../css/style.css';
import '../../css/skin/skin-1.css';
import '../../css/templete.min.css';
import ScrollToTop from '../element/scrollToTop';
const bg = require('../../images/banner/bnr1.jpg')

class Contactus extends Component {
    state = {
        isOpen: false,
        
    }

    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Contact Us </h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="index">Home</Link></li>
                                        <li>Contact Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="section-full content-inner contact-page-8 bg-white">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 m-b30">
                                            <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div class="icon-content">
                                                    <h5 class="dlab-tilte">
                                                        <span class="icon-sm text-primary"><i class="ti-location-pin"></i></span> 
                                                        Company Address
                                                    </h5>
                                                    <p>"Mahavir" opp. Rajkamal Petrol pump, Gondal Road, Rajkot - 360004</p>
                                                    <h6 class="m-b15 text-black font-weight-400"><i class="ti-alarm-clock"></i> Office Hours</h6>
                                                    <p class="m-b0">Mon To Sat - 10.00 - 07.00</p>
                                                    <p>Sunday - Close</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-6 m-b30">
                                            <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div class="icon-content">
                                                    <h5 class="dlab-tilte">
                                                        <span class="icon-sm text-primary"><i class="ti-email"></i></span> 
                                                        E-mail
                                                    </h5>
                                                    <p class="m-b0"> marketing@adityaimpex.biz</p>
                                                    <p class="m-b0"> sales@adityaimpex.biz</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-6 m-b30">
                                            <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div class="icon-content">
                                                    <h5 class="dlab-tilte">
                                                        <span class="icon-sm text-primary"><i class="ti-mobile"></i></span> 
                                                        Phone Numbers
                                                    </h5>
                                                    <p class="m-b0">+91-99133 44447</p>
                                                    <p class="m-b0">+91-99133 88887</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12 m-b30">
                                    <form class="inquiry-form wow fadeInUp" data-wow-delay="0.2s">
                                        <h3 class="title-box font-weight-300 m-t0 m-b10">Let's Convert Your Idea into Reality <span class="bg-primary"></span></h3>
                                        <p>We are well known Manufacturer & Exporter of all kind of
                                            Agriculture, Food processing equipment & Engineering
                                            Machinery & Precision Components</p>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-user text-primary"></i></span>
                                                        <input name="dzName" type="text" required class="form-control" placeholder="First Name"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-mobile text-primary"></i></span>
                                                        <input name="dzOther[Phone]" type="text" required class="form-control" placeholder="Phone"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group"> 
                                                        <span class="input-group-addon"><i class="ti-email text-primary"></i></span>
                                                        <input name="dzEmail" type="email" class="form-control" required  placeholder="Your Email Id" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-check-box text-primary"></i></span>
                                                        <input name="dzOther[Subject]" type="text"  class="form-control" placeholder="Upload File"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-file text-primary"></i></span>
                                                        <input name="dzOther[Subject]" type="text"  class="form-control" placeholder="Upload File"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-agenda text-primary"></i></span>
                                                        <textarea name="dzMessage" rows="4" class="form-control" required placeholder="Tell us about your project or idea"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <button name="submit" type="submit" value="Submit" class="site-button button-md"> <span>Get A Free Quote!</span> </button>
                                            </div>
                                        </div>
                                    </form>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter/>      
                <ScrollToTop className="icon-up" />
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>          
            </>
        )
    }
}
export default Contactus;