import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class CandleMakingMachine extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Candle Making Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Candle Making Machine Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        
                                        <h2 class="text-black font-weight-600 m-b15">Candle Making Machine:</h2>
                                        <br/>
                                        <img alt="" style={{height: 400, width: 500 }} src={require("../../images/Aditya impex Jpg/new candle making machine photo.jpg")}/>
                                        <br/><br/><br/>
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br/>
                                        <p>We offer a wide gamut of Candle Making Machine to our Indian and global clients at budget-friendly rates in the
                                        market. We are one of the major manufacturers, exporters and suppliers of Candle Making Machine, and we
                                        excel in offering the finest range of machine to the clients. We have a team of experts who processes the Candle
                                        Making Machine under advanced processing units and using high-grade materials. The Candle Making Machine,
                                        supplied by us, is in high demand as these are sturdy, and promise longer functional life. We are located in
                                        Gujarat, India.</p>
                                        <br/>
                                        <ul>
                                            <li>Round Pillar type candle making machine. We are manufacturing the candle making machine in different
                                            models.</li>
                                            <li>Our machine can make Round, Square & spiral type candles. We are also manufacturing Taillight Candle
                                            making machine in different models & designs. The following is detail of our Round candle making machine.</li>
                                        </ul>
                                        <br/>

                                        
                                       




                                    </div>
                                    <div class="col-lg-6">
                                    
                                        <div class="row">
                                        <h3 class="text-black font-weight-600 m-b15">Product specification:</h3>
                                        <br/>
                                        <div><br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}><center>Diameter of candle</center></th>
                                                    <th style={{ border:"1px black solid" }}><center>Height of candle</center></th>
                                                    <th style={{ border:"1px black solid" }}><center>No. of pieces at one time</center></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>5.5 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>139.7 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>300</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>6.5 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>139.7 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>300</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>7 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>139.7 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>300</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>8 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>165.1 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>300</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>8.5 mm </center></td>
                                                    <td style={{ border:"1px black solid" }}><center>165.1 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>300</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>9.5 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>190.5 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>300</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>10.5 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>190.5 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>284</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>11 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>215.9 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>240</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>12 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>215.9 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>200</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>12.5 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>215.9 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>200</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>13.5 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>215.9 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>200</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>14 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>215.9 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>184</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>15 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>241.3 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>160</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>16 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>241.3 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>160</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>17 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>241.3 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>144</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>19 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>266.7 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>120</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>21 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>266.7 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>96</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>23 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>292.1 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>84</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>24 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>292.1 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>72</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}><center>25.4 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>304.8 mm</center></td>
                                                    <td style={{ border:"1px black solid" }}><center>60</center></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                 
            </>
        )
    }
}
export default CandleMakingMachine;
