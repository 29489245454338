import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class CupFilling extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Cup Filling & Sealing Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Cup Filling & Sealing Machine Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Cup Filling & Sealing Machine Details:</h2>
                                        <br/><br/>
                                        <div class="col-lg-6">
                                        <img alt=""style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/cup-filling - Copy.jpg")}/> 
                                        </div>
                                        <br/>
                                        <h3>Product Details:</h3>             
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                            <tr>
                                                <th style={{ border:"1px black solid" }}>Brand</th>
                                                <td style={{ border:"1px black solid" }}>Aditya</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border:"1px black solid" }}>Automation Grade</th>
                                                <td style={{ border:"1px black solid" }}>Automatic</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border:"1px black solid" }}>Material</th>
                                                <td style={{ border:"1px black solid" }}>Mild Steel</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border:"1px black solid" }}>Voltage (V)</th>
                                                <td style={{ border:"1px black solid" }}>220</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border:"1px black solid" }}>Usage/Application</th>
                                                <td style={{ border:"1px black solid" }}>Industrial</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                
                                                </tbody>
                                        </table>
                                        <br/>
                                       
                                        


                                        


                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                        <h3>Fully Automatic Tray Sealing Machine VP 600-Vacuum/GasFlush(MAP)/Skin Packing</h3>
                                        <br/><br/>
                                        <h3>Applications:</h3>
                                        <br/>
                                        <p>Online Meal Packing,Sweets Packing MAP,Ready made Meals,Salads Packing etc.</p>
                                        <br/>
                                        <h3>Features:</h3>
                                        <br/>
                                        <p>Output : 1000 - 5000 Trays/hr
                                        Sturdy construction with stainless steel cover
                                        Vacuum with Gas Flushing /Gas Flushing funciton selection
                                        Heating element with teflon coating and AL anodized
                                        Quick interchangeable mould / sealing tool design
                                        Fully safety guard defense for security
                                        Accurate film rewinding system design
                                        Film feed controlled by sensor, encorder, or time (available for plain film and
                                        printed film</p>
                                        <br/>
                                        <h3>MAP Result:</h3>
                                        <br/>
                                        <p>Inert gases: nitrogen, carbon dioxide, noble gases Option residual oxygen level:achieved up to 0.1%</p>
                                        <br/>
                                        <div class="col-lg-6">

                                        <h3>Optional Function:</h3>        
                                        
                                        <ul>
                                        <li>1.Vacuum + Gas flushing(MAP)</li>
                                            <li>2.Gas flushing(MAP) only</li>
                                            <li>3.Inside cutting</li>
                                            <li>4.Skin pack</li>
                                            <li>5.UV light sterilizer</li>
                                        </ul>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>         
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                 
            </>
        )
    }
}
export default CupFilling;
