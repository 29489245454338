import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import RenewableenergyPortfolio from '../element/renewableenergyPortfolio';
import RenewableSlider from '../element/renewableSlider';
import {blogContent3,projectContent1,renewableSliderContent} from '../element/SliderContent'
import AdityaCounterSection from '../element/adityaCounterSection';
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';

const bg = require('../../images/banner/bnr4.jpg')
const bg2 = require('../../images/background/bg4.jpg' )

class RenewablePortfolio extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>

                <div class="page-content bg-white">
                <RenewableSlider 
                data={renewableSliderContent}
                contentWrapperCls="sliderStyle3"
                hideBtn
                />
                    <RenewableenergyPortfolio col="col-md-3"/>
                    {/* <AdityaCounterSection backgroundImage={bg2}/> */}
                    
                </div>                
                <AdityaCounterSection backgroundImage={bg2} />
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>    
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>              
            </>
        )
    }
}
export default RenewablePortfolio;