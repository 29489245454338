import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class PaverBlock extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Pever Block Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Construction Machinery</li>
                                        <li>Pever Block Machine Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Pever Block Machine Details:</h2>
                                        <br/>
                                        
                                        <p>Our company is involved in manufacturing, trading and supplying Automatic
                                        Paver Blocks Making Machine that comes in rugged construction. Owing to
                                        the 10,000 to 25,000 Fly Ash Bricks 6000Pcs to 18,000 Paver Blocks in 8 Hrs.
                                        bricks production and 400 Liters of oil tank capacity, the proffered construction
                                        machine is highly demanded in market. The proposed selection requires 28
                                        HP of power. Before the final send off, the series is checked for its
                                        flawlessness.</p>   
                                        <br/><br/>
                                        <h3>Features:</h3>                                 
                                        
                                        <ul>
                                            <li>Longer service life</li>
                                            <li>Ruggedly constructed</li>
                                            <li>Less maintenance needed</li>
                                        </ul>
                                        <br/><br/>
                                        <h3>Specifications:</h3>
                                        <ul>
                                            <li>Construction: Maximum High Pressure Tonege: 100 to 120 Tons</li>
                                            <li>Power Required : 28 HP- 32 H.P</li>
                                            <li>(Main Press: 10Hp, Pan Mixer-2 Nos-15 Hp,Vibrator-1 Hp)</li>
                                            <li>Manufacturing Capacity: Fly Ash Bricks 6,8, 10,12, 14,16 Pcs(In One
                                            Operation) And Paver Blocks: 6,8,10 12 Paver Blocks of 60mm 80 mm In
                                            One Operation</li>
                                            <li>Facilitys: Fly Ash Bricks Making Machine Single Feeder Facilites For
                                            Production Of Fly Ash Bricks</li>
                                            <li>Production: Fly Ash Bricks 10,000 to 25,000 pcs in 8 hrs Paver Blocks
                                            6,000 to 18,000 Paver Blocks in 8 Hrs.</li>
                                            <li>(In Shift 8 Hours)</li>
                                            <li>Bricks Size: 230x 100 x 75, 250 x 125 x 75</li>
                                            <li>Oil Tank Capacity: 400 Liters</li>
                                            <li>Superiors Vibration in Brick Press</li>
                                        </ul>

                                    </div>
                                    <div class="col-lg-6">
                                        <br/><br/><br/>       
                                        <div class="row">
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img alt=""style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/PAVERS BLOCK MACHINE.jpg")}/>
                                           <div> <br/><br/><br/><br/>
                                            <h3>Product Details:</h3>
                                        
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Minimum Order Quantity</th>
                                                    <td style={{ border:"1px black solid" }}>1 Set</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Capacity (Bricks per Hour) </th>
                                                    <td style={{ border:"1px black solid" }}>500-1000,1000-1500,1500-2000</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Raw Material</th>
                                                    <td style={{ border:"1px black solid" }}>Cement, Stone Dust</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Automation </th>
                                                    <td style={{ border:"1px black solid" }}>Semi-Automatic</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Brand</th>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Capacity </th>
                                                    <td style={{ border:"1px black solid" }}>1000-1500</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                            </tbody>
                                        </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>               
            </>
        )
    }
}
export default PaverBlock;