import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class ChaffCutterDetails extends Component {
    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Chaff Cutter Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Agriculture Equipments</li>
                                        <li>Chaff Cutter Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black  m-b15">Chaff Cutter Details:</h2>
                                        <br />
                         
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                               
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                   
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automation Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Semi-automatic</td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Cutting Blade Material </td>
                                                    <td style={{ border:"1px black solid" }}>High Carbon Steel</td>
                                                    
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                        <br/>
                                        <p>Riding on unfathomable volumes of industrial expertise, we are providing a broad
                                        array of <strong>Electric Chaff Cutter.</strong></p>
                                        <br />
                                        <h4>Other Details:</h4>
                                        <br />
                                        <ul>
                                            <li>Chaff Cutter Machine Body Is made With MS Steel Material So It Is Durable and
                                            Providing Long Life Duty.</li>
                                            <li>Maximum Care IS Taken For The Safety Of operator While designing the
                                            Machine.</li>
                                        </ul>
                                        
                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/><br/>

                                        <div class="row">.
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img alt="" style={{height: 400, width: 400 }}src={require("../../images/Aditya impex Jpg/chaff-cutter.jpg")}/>
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>               
            </>
        )
    }
}
export default ChaffCutterDetails;