import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class BarStock extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Bar Stock Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Segments</li>
                                        <li>Precision Component Development</li>
                                        <li>Bar Stock Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Bar Stock Details:</h2>
                                        <br/><br/><br/>

                                        <img style={{height: 400, width: 400 }} alt="" src={require("../../images/Aditya impex Jpg/bar_stock_components.jpg")}/>
                                        <br/><br/><br/><br/>
                                        <img style={{height: 400, width: 400 }} alt="" src={require("../../images/Aditya impex Jpg/BIG.jpg")}/>

                                        <br/><br/><br/><br/>
                                        
                                        <p>Bar Stock is widely used in many industries and can be seen in many different industrial
                                        processes. These processes include forging, extrusion, machining, and many more.
                                        In Forging, billets are heated to high temperatures before a press pushes the work piece
                                        into the shape on the die.</p>
                                        <br/><br/><br/><br/><br/><br/><br/><br/>

                                        <h2 class="text-black font-weight-600 m-b15">Ring Rolling Details:</h2>
                                        <br/>
                                        <p>Seamless rolled ring forging is typically performed by punching a hole in a thick, round piece of metal (creating a
                                        donut shape), and then rolling and squeezing (or in some cases, pounding) the donut into a thin ring.
                                        </p>
                                        <br/>
                                        <p>The process starts with a circular perform of metal that has been previously upset and pierced (using the open
                                        die forging process) to form a hollow "donut". This donut is heated above the recrystallization temperature and
                                        placed over the idler or mandrel roll. This idler roll then move under pressure toward a drive roll that continuously
                                        rotates to reduce the wall thickness, thereby increasing the diameters (I.D. and O.D.) of the resulting ring.
                                        </p>
                                        <br/>
                                        <p>High tangential strength and ductility make forged rings well-suited for torque- and pressure-resistant
                                        components, such as gears, engine bearings for aircraft, couplings, rotor spacers, sealed discs and cases,
                                        flanges, pressure vessels and valve bodies. Materials include not only carbon and alloy steels, but also nonferrous alloys of aluminum, copper and titanium, as well as nickel-base alloys.</p>

                                        <br/><br/><br/><br/><br/><br/><br/><br/>



                                        

                                       

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                        <h2 class="text-black font-weight-600 m-b15">Carbon Steel Details:</h2>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Carbon Steel Forging Options:</h3>
                                        
                                        <p>Depending on your project needs there is a wide range of available options when it
                                        comes to carbon steel grades. All four grades can be heat treated to improve their
                                        machinability, strength, and ductility. Our carbon steel forging options include:</p>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Medium Carbon Steel:</h3>
                                        <p>Medium grade carbon steel has approximately 0.29% to
                                        0.54% carbon content with 0.60 to 1.65% manganese content (e.g. AISI 1045 steel).
                                        This grade balances ductility and strength and has good wear resistance; it used for
                                        large parts, forging and car parts.
                                        </p>
                                        <br/>
                                        <div>
                                        <h3 class="text-black font-weight-600 m-b15">Carbon Steel Grades:</h3>
                                        </div>
                                        <div class="col-lg-6">
                                        <ul>
                                            <li>1030</li>
                                            <li>1045</li>
                                            <li>1117</li>
                                        </ul>
                                        <br></br><br></br><br></br><br></br>
                                        </div>

                                        
                                        <div class="col-lg-12">                                  
                                        <h2 class="text-black font-weight-600 m-b15">Alloy Steel Details:</h2>
                                        </div>
                                        

                                        <br></br>
                                        
                                        
                                        <h3 class="text-black font-weight-600 m-b15">Alloy Steel Forgings</h3>
                                        <p>Forged alloy steel is a steel that has had small amounts of one or more alloying
                                        elements, other than carbon, added to its overall content. These added elements can
                                        include manganese, silicon, nickel, titanium, copper, chromium, aluminum and more.
                                        These added elements create specific properties for forgings that are not found in
                                        regular carbon steel forgings. In addition, alloy steels have become increasingly
                                        popular due to their availability, lower economical cost, excellent mechanical
                                        properties and ease of processing.</p>
                                        
                                        <br/>

                                        <p>If you have any questions regarding custom alloy steel forging contact us, or request
                                        a quote for an in-depth price analysis.</p>
                                        <br/>

                                        <div class="col-lg-6 m-b30">
                                        <h3 class="text-black font-weight-600 m-b15">Alloy Steel Grades</h3>
                                        
                                        <ul>
                                            <li>4130</li>
                                            <li>4140</li>
                                            <li>4150</li>
                                            <li>4340</li>
                                            <li>4340AQ</li>
                                            <li>A105</li>
                                            <li>A182F22</li>
                                            <li>A350LF2</li>
                                            <li>Nitralloy 135MOD</li>
                                        </ul>
                                        </div>


                                        <br/><br/><br/><br/><br/><br/><br/><br/>


                                        <div>
                                        <h2 class="text-black font-weight-600 m-b15">Non Alloy Steel Details:</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>              
            </>
        )
    }
}
export default BarStock;
