import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class SolarPump extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Solar Pump Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Non Renewable Energy</li>
                                        <li>Solar Pump Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Solar Pump Details:</h2>
                                        <br/>
                                        <br/>
                                        <img alt=""style={{height: 300, width: 300 }} src={require("../../images/Aditya impex Jpg/SOLAR PUMP  NEW.jpg")}/>
                                        <br/><br/><br/><br/>
                                        <img alt="" src={require("../../images/Aditya impex Jpg/solar.png")}/>
                                        <br/><br/><br/><br/>
                                        <h3>APPLICATION AREA: </h3>
                                        <p>This project products are mainly used by dry region for
                                        irrigation of agriculture. It can be used by human for
                                        drinking water and living water use. The living condition had
                                        been much improved. It also can be used for fountain.</p>
                                        <br/>
                                        <h3>ACCESSORIES:</h3>
                                        <p>Pump Control, Screw, Cable connector, Water level
                                        sensor, Cable for solar panel, Solar flashlight.</p>
                                        <br/>
                                       
                                        



                                       

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                        <div>
                                        <br/>
                                        <h3>ADVANCED TECHNOLOGY</h3>
                                        <br/>
                                        <h3>1. Application innovation</h3>
                                        <br/>
                                        <p>Compared with the traditional alternating current machine, the efficiency is
                                        improved 25% by the permanent magnetism, direct current, brushless, nonsensor motor</p>
                                        <br/>
                                        <h3>2. Technique innovation</h3>
                                        <br/>
                                        <p>Compared with CVT technique, adopting MPPT technique, using automatic
                                        follow max power point, the motor efficiency can be improved 15%. Drive part
                                        adopts DSP chip for main control technique, which achieved TMPPT control and
                                        makes the system with high efficiency, credibility and good price performance
                                        ratio.</p>
                                        <br/>
                                        <h3>3. Technics innovation</h3>
                                        <br/>
                                        <p>Adopt double plastic package for Rotor and Stator, Motor insulation  300 M W,
                                        the motor security was much improved.</p>
                                        <br/>
                                        <h3>4. Structure innovation</h3>
                                        <p>Water filling or oil filling, convent installation and environmental protection
                                        5. The materials of whole body are made of stainless steel and bronze.</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>               
            </>
        )
    }
}
export default SolarPump;
