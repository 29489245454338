import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class CpvcPipeDetails extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">CPVC & Galvanized Pipes And Fittings Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Agriculture Equipments</li>
                                        <li>CPVC & Galvanized Pipes And Fittings Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">CPVC & Galvanized Pipes And Fittings Details:</h2><br/>
                                        <h3 class="text-black font-weight-600 m-b15">Pipe Fittings made from Stainless Steel:</h3>
                                        <br/>
                                        <h2 class="text-black font-weight-600 m-b15">Galvanized Pipes:</h2><br/>


                                        <div class="col-lg-12 m-b30">
                                                <img alt=""style={{height: 400, width: 400 }} src={require("../../images/PVC-PIPE/COL PIPE 2.png")}/>
                                        </div>
                                        <br/>

                                        <ul>
                                            <li>Half Socket</li>
                                            <li>Welding Nipple</li>
                                            <li>Barrel Nipple</li>
                                            <li>Straight Nipple</li>
                                            <li>Hexagonal Nut</li>
                                            <li>Double Nipple</li>
                                            <li>Cap</li>
                                            <li>Bushing</li>
                                            <li>Reducing Nipple</li>
                                            <li>Reducing Socket</li>
                                            <li>Tee</li>
                                            <li>Elbow</li>
                                            <li>Street Elbow</li>
                                            <li>Hexagonal Plug</li>
                                            <li>Square Head Plug</li>
                                            <li>Hose Nipple - Hexagonal Catch</li>
                                            <li>Hose Nipple for Welding</li>
                                            <li>Hose Nipple</li>
                                            <li>Coupling, conical seat</li>
                                            <li>Coupling, flat seat</li>
                                            <li>Collar</li>
                                        </ul>
                                        <h3 class="text-black font-weight-600 m-b15">Machined from:</h3>
                                        <ul>
                                            <li>Hexagonal or Round Bar Stock</li>
                                            <li>Forgings</li>
                                            <li>Investment Casting</li>
                                        </ul>
                                        <h3 class="text-black font-weight-600 m-b15">Threading:</h3>
                                        <ul>
                                            <li>Withworth, NPT, NPTF, API, PS, PG</li>
                                        </ul>
                                        <h3 class="text-black font-weight-600 m-b15">Material Approval:</h3>
                                        <ul>
                                            <li>AD2000 and PED 97/23/EC, wherever applicable.</li>
                                        </ul>
                                        <h3 class="text-black font-weight-600 m-b15">Pipe Fittings from Gunmetal Castings & SS Investment Castings:</h3>
                                        <ul>
                                            <li>Swivel Stub Assembly</li>
                                            <li>Weld Stub Assembly</li>
                                            <li>Lug Nuts</li>
                                            <li>Connectors</li>
                                            <li>Plugs</li>
                                            <li>Hex Male</li>
                                            <li>Female Liner</li>
                                        </ul>
                                        <br/>
                                        {/* <h2 class="text-black font-weight-600 m-b15">PVC Flat Hose Pipe:</h2><br/>
                                        <div class="col-lg-12 m-b30">
                                        <img alt=""style={{height: 500, width: 500 }} src={require("../../images/PVC-PIPE/CPVC PIPE FITTING 2.jpg")}/>
                                        </div>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                               
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Color</td>
                                                    <td style={{ border:"1px black solid" }}>Blue</td>
                                                   
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Size</td>
                                                    <td style={{ border:"1px black solid" }}>1 inch, 3 inch, 2 inch</td>
                                                    
                                                </tr>
                                                 <tr>
                                                    <td style={{ border:"1px black solid" }}>Single Hose Length</td>
                                                    <td style={{ border:"1px black solid" }}>20m-30m</td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table> 
                                        <br/>
                                        <p>PVC Flat Hose is a superior replacement to conventional cotton/kisan/Lapeta
                                        hose pipes. It is the first and only product made in India of this kind.</p>
                                        <br/>
                                        <p>Composition : The hose is composed of flexible PVC with synthetic yarn
                                        reinforcement to provide extra strength.</p> */}
                                        <br/>
                                        

                                        <div class="col-lg-12 m-b30">
                                            {/* <img alt="" src={require("../../images/PVC-PIPE/COL PIPE 1.png")}/> */}
                                        </div>
                                        
                                        
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">

                                        <h2 class="text-black font-weight-600 m-b15">PVC Pipes & Fittings:</h2><br/>
                                        <div class="col-lg-12 m-b30">
                                            <img alt=""style={{height: 400, width: 400 }} src={require("../../images/PVC-PIPE/CPVC PIPE FITTING.jpg")}/>
                                        </div>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                               
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Single Hose Length </td>
                                                    <td style={{ border:"1px black solid" }}>12m-20m, 20m-30m</td>
                                                   
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Size</td>
                                                    <td style={{ border:"1px black solid" }}>3 inch, 2 inch, 1 inch</td>
                                                    
                                                </tr>
                                                
                                            </tbody>
                                        </table> 
                                        <br/>
                                        <p>Ideal for Light Duty collection conveying of Air, Gases, Dust and Light
                                        materials. Used for conduits of air, industrial ventilation, collecting dust with
                                        exhaust fans in stone masoning, fiberglass, textiles, asbestos processing and
                                        other industries, cooling of motors and air intakes for all machineries,
                                        conveying warm air for drying, vacuum cleaning equipments, air-conditions for
                                        offices, kitchen-range overhead exhaust, etc</p>

                                            {/* <div class="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/PVC-PIPE/COL PIPE 2.png")}/>
                                            </div> */}
                                            {/* <div class="col-lg-12 m-b30"> */}
                                                {/* <img alt="" src={require("../../images/PVC-PIPE/COL PIPE.jpg")}/> */}
                                            {/* </div> */}
                                            {/* <div class="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/PVC-PIPE/CPVC PIPE FITTING 2.jpg")}/>
                                            </div> */}
                                            {/* <div class="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/PVC-PIPE/CPVC PIPE FITTING.jpg")}/>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>               
            </>
        )
    }
}
export default CpvcPipeDetails;