import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class CultivatorDetails extends Component {

    
    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Cultivation Detail</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Pre Harvesting</li>
                                        <li>Cultivation Detail</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        {/* <h2 class="text-black font-weight-600 m-b15">Cultivation Detail</h2> */}
                                        <br />

                                        <h3 class="text-black font-weight-600 m-b15">Rigid Cultivation</h3><br/><br/>
                                        <div class="col-lg-12 m-b30">
                                                <img alt="" style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/RIGID CULTIVATOR copy.jpg")}/>
                                                <br/><br/><br/>

                                                 

                                            </div>
                                        
                                        <p>Rigid cultivator is capable to withstand tough working conditions at soils. It is useful to break
                                        up hard soil efficiently and uproots stubbles which leaves the ground perfectly work. Also it is useful
                                        for stirring and pulverizing the soil before planting.</p>
                                        <br /><br/><br/><br/>

                                        <h3 class="text-black font-weight-600 m-b15"> Spring Cultivation</h3><br/>
                                        <div class="col-lg-12 m-b30">
                                            <img alt=""style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/SPRING CULTIVATOR copy.jpg")}/>

                                            <br/><br/><br/><br/>

                                            

                                        </div>
                                        <p>Spring cultivator is more complex in design and mainly used to deliver sturdy
                                        performance at light, medium or tough soil. Special type of springs help to simplify the task
                                        by reducing the manual load. Owing to their design structure, therse are ideal to be used in
                                        root obstructed soil.</p>
                                        <br/><br/><br/><br/>

                                        <h3 class="text-black font-weight-600 m-b15">Row Crop Cultivation</h3>
                                        <div class="col-lg-12 m-b30">
                                                <img alt="" style={{height: 400, width: 400 }}src={require("../../images/Aditya impex Jpg/ROW CROP CULTIVATOR.jpg")}/>
                                            </div>
                                        <p>Row Crop Cultivator gives a high work flow with very reduced power consumption. It is
                                        capable to do quick & efficient work. This Cultivator is also used as a secondary source of tillage and
                                        functions by tilling the soil in designated rows so as not to disturb them, The main function of Row
                                        Crop Cultivator is to control weed between the rows of an established crop.</p>
                                        <br/><br/>

                                       

                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/><br/><br/>
                                        <div class="row">
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                
                                                <tr>
                                                <th style={{ border:"1px black solid" }}><center>Variant</center></th>
                                                <th style={{ border:"1px black solid" }}><center>KARC 09</center></th>
                                                <th style={{ border:"1px black solid" }}><center>KARC 11</center></th>
                                                <th style={{ border:"1px black solid" }}><center>KARC 13</center></th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Frame (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>72*72*5 Tubular Frame</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Tines (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>25</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Shovels (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>8(EN-45)</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Width (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>1950</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>2362</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>2819</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Working Width (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>1805</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>2262</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>2719</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Weight (Kg)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>210</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>250</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>290</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Mounted CAT</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>CAT ||</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Tractor Power (HP)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="2"><center>35-55</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>55-75</center></td>
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                        </div>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>


                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                
                                                <tr>
                                                <th style={{ border:"1px black solid" }}><center>Variant</center></th>
                                                <th style={{ border:"1px black solid" }}><center>KASC 09</center></th>
                                                <th style={{ border:"1px black solid" }}><center>KASC 11</center></th>
                                                <th style={{ border:"1px black solid" }}><center>KASC 13</center></th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Frame (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>72*72*5 Tubular Frame / 50*50*10 Angle Frame</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Tines (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>20(Forged)</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Spring (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>9.5 WD, 50 OD, 27 Coils</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Shovels (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>8 (EN-45)</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}> Width (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>1905</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>2362</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>2819</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Working Width (mm)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>1805</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>2262</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>2719</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Weight (Kg)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>170</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="1"><center>217</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>264</center></td>                                              
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Mounted CAT</td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>CAT ||</center></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Tractor Power (HP)</td>
                                                    <td style={{ border:"1px black solid" }} colspan="2"><center>35-55</center></td>
                                                    <td style={{ border:"1px black solid" }} colspan="3"><center>55-75</center></td>
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                            
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                
            </>
        )
    }
}
export default CultivatorDetails;