import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class LathMachine extends Component {
    state = {
        isOpen: false,
        
    }
    
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Lath Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Workshop Machinery</li>
                                        <li>Lath Machine Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Lath Machine Details:</h2>
                                        <br/>
                                       
                                        <p>Keeping the ease of our customers in mind, we offer customized solutions. Ethical business
                                        policies and professional approaches are followed while working in close coordination with our
                                        customers, committed to attaining maximum customer approach and satisfaction. The offered
                                        lathe machines are available with us in various capacities and designs, which suit the
                                        requirements of our valuable customers in the most efficient manner. The offered lathe machines </p>
                                        <ul>
                                            <li>Hardened bed ways</li>
                                            <li>Chuck back plate 1 no</li>
                                            <li>Centre adapter</li>
                                            <li>Dead centres 2 nos</li>
                                            <li>Tool Post Spanner</li>
                                            <li>Motor pulley</li>
                                            <li>Thread dial indicator</li>
                                            <li>Change gear set – 1 (For metric and inch both)</li>
                                            <li>Chip Tray</li>

                                        </ul>
                                        <br/>
                                        <h3>Optional Accessories: </h3>
                                        <ul>
                                            <li>Face plate</li>
                                            <li>Carrier plate</li>
                                            <li>Steady rest</li>
                                            <li>Follow rest</li>
                                            <li>Taper turning attachment</li>
                                            <li>Motorised coolant pump with tank and fittings</li>
                                            <li>Rear tool post with extended cross slide</li>
                                            <li>Chuck back plates (Extra)</li>
                                            <li>Electricals (Motor, Switch, wirings, V-belts and fittings)</li>
                                            <li>Norton Gear Box</li>

                                        </ul>
                                        <br/>
                                       
                                       

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                        <img alt="" src={require("../../images/Aditya impex Jpg/LATHE MACHINE.jpg")}/>
                                            <div><br/>
                                            <h3>Salient Features:</h3>    
                                        <ul>
                                            <li>Head stock spindle are made by alloy steel & precision grinding</li>
                                            <li>Our ultimate goal is to win the full satisfaction of our client and we are committed to thegoal.</li>
                                            <li>All rotating parts are balanced</li>
                                            <li>Rajkot head quarters will ship a needed replacement part to the customer the same dayit’s ordered 99% of the time.</li>
                                        </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>             
            </>
        )
    }
}
export default LathMachine;
