import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class PeterTypeEngine extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader />
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Peter Type Engine Detail:</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Agriculture Equipments</li>
                                        <li>Peter Type Engine Detail</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Peter Type Engine Detail</h2>
                                        <br />

                                        <div class="col-lg-8 m-b30"><br/>
                                            <img alt="" style={{ height: 300, width: 300 }} src={require("../../images/Aditya impex Jpg/01 petter type engine.jpg")} /><br/><br/>
                                        </div>
                                        <br/>
                                        <br />
                                        {/* <h3>Peter Type Engine Detail:</h3> */}
                                        <br />
                                       


                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/>
                                        <div class="row">
                                        
                                        <table style={{ border: "1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border: "1px black solid" }}>Brand</th>
                                                    <td style={{ border: "1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border: "1px black solid" }}>Automation Grade</th>
                                                    <td style={{ border: "1px black solid" }}>Manual, Automatic</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border: "1px black solid" }}>Power Source</th>
                                                    <td style={{ border: "1px black solid" }}>Diesel</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border: "1px black solid" }}>Speed Range In RPM </th>
                                                    <td style={{ border: "1px black solid" }}>1500</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border: "1px black solid" }}>Number Of Cylinder</th>
                                                    <td style={{ border: "1px black solid" }}>1 and 2</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ border: "1px black solid" }}>Power</th>
                                                    <td style={{ border: "1px black solid" }}>3.5-22 hp</td>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ border: "1px black solid" }}>Tolerance</th>
                                                    <td style={{ border: "1px black solid" }}>20 Microns</td>
                                                </tr> */}
                                            </thead>
                                            <tbody>
                                                <tr>


                                                </tr>
                                            </tbody>
                                        </table>
                
                                        <br />

                                        <p>The Petter Type Diesel Engines offered by us are suitable for almost all
                                        sizes of engine and depends on the type, speed and the effort of engine.
                                        These are extremely durable, and precision engineered. Our team of efficient
                                        engineers designs the entire range meticulously. Further, these are stringently
                                        checked to ensure that the best and flawless range reaches the market.</p>
                                        <br />
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>        
            </>
        )
    }
}
export default PeterTypeEngine;