import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class HandPumpDetails extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Deepwell & Treadle Pump Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Agriculture Equipments</li>
                                        <li>Deepwell & Treadle Pump Deatils</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Deepwell & Treadle Pump:</h2>
                                        <br/>
                                        <h2 class="text-black font-weight-600 m-b15">Salient Features & Technical Details:</h2>
                                        <br/>
                                        <ul>
                                            <li>Development of a sturdy and reliable community workshops at low cost.</li>
                                            <li>Fully fabricated steel structure (duly hot dip galvanized) makes the above ground portion of pump very sturdy and corrosion resistant.</li>
                                            <li>Capable of drawing water from 5 to 90 meters operating depths.</li>
                                            <li>Reducing pumping effort to minimize the burden on women.</li>
                                            <li>Easy installation and inexpensive maintenance at village level, with little training.</li>
                                            <li>Suitable for open well installation.</li>
                                            <li>Easy availability of spare parts.</li>
                                        </ul>
                                        <br/><br/><br/><br/><br/><br/><br/>

                                        <h2 class="text-black font-weight-600 m-b15">Treadle Pump Details:</h2>
                                        <br/>
                                        <p>
                                        The MoneyMaker Max Pump is a low-cost, human-powered treadle pump designed by KickStart
                                        International for use in irrigation by small scale farmers. KickStart aims to help small-holder farmers to
                                        start family enterprises using these pumps, thereby enabling the farmers to lift their families out of poverty.
                                        </p>
                                        <br/>
                                        <h3>Competitive landscape</h3>
                                        <br/>
                                        <p>Rock Water Pump, Vergnet-Hydro 60-2000 Pum Target user(s) Small-scale farmers.
                                        </p>
                                        <br/>
                                        <h3>Distributors/implementing organizations</h3>
                                        <br/>
                                        <p>In the countries surrounding each hub, KickStart maintains a lighter staff footprint and works through
                                        networks of partners and distributors that include: NGOs, Governments, Churches, Farmer Organisations,
                                        UN Agencies and Bodies, Agricultural dealers and local distributors.</p>
                                        <br/>
                                        <p>Aditya brand is a major distributor of the pump</p>
                                        <br/>
                                        <h3>Manufacturing/building method</h3>
                                        <br/>
                                        <p>Mass produced. KickStart uses an intermediary QC and sourcing company in China to meet goals to
                                        reduce cost, control quality, and expedite supply chain management.</p>







                                        <div class="col-lg-12 m-b30">
                                        <br/><br/><br/><br/>
                                            <img alt=""style={{height: 500, width: 500 }} src={require("../../images/portfolio/AFRIDEV.jpg")}/>
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-6">
                                    <br/>
                                        <div class="row">
                                        
                                        <div class="col-lg-12 m-b30">
                                        
                                            <img alt=""style={{height: 500, width: 500 }} src={require("../../images/Aditya impex Jpg/INDIA MARK -2.jpg")}/>
                                        </div>
                                            <div class="col-lg-12 m-b30">
                                                <br/><br/><br/><br/><br/><br/><br/><br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img alt="" src={require("../../images/Aditya impex Jpg/treadle-pump.jpg")}/>
                                            </div>
                                            {/* <div class="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/portfolio/HAND DEEPWELL SPARE PARTS.jpg")}/>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                
            </>
        )
    }
}
export default HandPumpDetails;