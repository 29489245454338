import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class DrumCleaner extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Drum Cleaner Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Other Harvesting Products</li>
                                        <li>Drum Cleaner Machine Details</li>
                                 </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                    
                                       
                                        <h2 class="text-black font-weight-600 m-b15">Drum Cleaner Machine:</h2>
                                        <br/>
                                        <h3>Product Details:</h3>
                                        <br/>

                                        <table style={{ border:"1px black solid" }}>
                                                
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Material</td>
                                                    <td style={{ border:"1px black solid" }}>Steel</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Capacity</td>
                                                    <td style={{ border:"1px black solid" }}>2 Ton TO 40 Ton</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Model Number/Name </td>
                                                    <td style={{ border:"1px black solid" }}>Various</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage/Application</td>
                                                    <td style={{ border:"1px black solid" }}>Pre Cleaning & Grading</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Voltage</td>
                                                    <td style={{ border:"1px black solid" }}>220 V</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Motor power</td>
                                                    <td style={{ border:"1px black solid" }}>1 hp to 3 hp</td>
                                                </tr>
                                            </tbody>
                                        </table>  
                                        <br/>
                                       
                                                     
                                        
                                        <p>We are Manufacturer and exporter of Drum Cleaner These Drum Cleaner
                                        and also enabled with inbuilt special rotary sieves which offer high quality
                                        grading of various seeds and food grains.</p>

                                       
                                       
                                        
                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/><br/><br/><br/><br/>
                                        <div class="row">
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<img alt="" style={{height: 400, width: 400 }} src={require("../../images/Aditya impex Jpg/drum-cleaner-vibratory-separator-500x500.jpg")}/>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                
                    
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>               
            </>
        )
    }
}
export default DrumCleaner;
