import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
import '../../css/plugins.css';
import '../../css/style.css';
import '../../css/skin/skin-1.css';
import '../../css/templete.min.css';
const bg = require('../../images/banner/bnr4.jpg')

class AllTypePowerTools extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">All Type Of Power Tools Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Workshop Machinery</li>
                                        <li>All Type Of Power Tools Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">All Type Of Power Tools Details:</h2>
                                        <br/>
                                        
                                        <h4>Product Specification:</h4>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                               
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage/Application </td>
                                                    <td style={{ border:"1px black solid" }}>Industrial</td>
                                                   
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Warranty</td>
                                                    <td style={{ border:"1px black solid" }}>6 months</td>
                                                    
                                                </tr>
                                                </tbody>
                                        </table> 
                                        <br/>
                                        <h4>Product Description:</h4>
                                        <br/>
                                        <p>All Power Tools. 1 Angle Grinder. 2. Drill Machine. 3. Miter Saw. 4. Marbal Cutter.
                                        5.Blower. 6.Impact Drill. 7.Rotary Hammer</p>




                                      
                                       

                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/><br/><br/>
                                        <div class="row">
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img alt="" src={require("../../images/Aditya impex Jpg/powertool.jpg")}/><br/>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>                
            </>
        )
    }
}
export default AllTypePowerTools;
