import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class MustardOilMill extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader />
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Mustard Oil Mill Machine</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Mustard Oil Mill Machine</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">

                                        <h2 class="text-black font-weight-600 m-b15">Mustard Oil Mill Machine:</h2>
                                        <br />
                                        {/* <img alt="" style={{ height: 500, width: 500 }} src={require("../../images/Aditya impex Jpg/groundnutsl-milling-machine-500x500.jpg")} /> */}

                                        <br />
                                        <div>

                                            <br />

                                            <h3 class="text-black font-weight-600 m-b15">Product Details:</h3>


                                            <br />

                                            <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Set</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automation Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Semi-Automatic, Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Design</td>
                                                    <td style={{ border:"1px black solid" }}>Standard</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Usage</td>
                                                    <td style={{ border:"1px black solid" }}>Peanut Oil, Palm Fruit Oil, Sunflower Oil, Cotton Seed Oil, Soybean Oil</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Capacity</td>
                                                    <td style={{ border:"1px black solid" }}>1-5 ton/day, 5-20 ton/day, 20-60 ton/day, 60-100 ton/day, 100-200 ton/day, 80kg per hour - 5ton per hour</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Brand</td>
                                                    <td style={{ border:"1px black solid" }}>Aditya</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Material</td>
                                                    <td style={{ border:"1px black solid" }}>M.S & C.I</td>
                                                </tr>
                                                
                                                
                                            </tbody>
                                        </table>
                                        
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Specifications:</h3>
                                        <br/>
                                        <ul>
                                            <li>Chamber Size: 610 x 112mm</li>
                                            <li>Capacity (Per Hour): 70 kg</li>
                                            <li>Power Required (in B.H.P): 10H.P</li>
                                            <li>Approximate Weight: 950 Kg.</li>
                                            <li>Space required (For Fitting): 3 x 5 Meter</li>
                                            <li>R.P.M. of main shaft: 35</li>
                                            <li>R.P.M. of pulley: 175</li>
                                            <li>Dia. of pulley: 56 cm</li>
                                            <li>Face of Pulley: 10 cm</li>
                                        </ul>
                                        <br/>
                                            
                                            
                                        </div>
                              

                                    </div>
                                    <div class="col-lg-6">
                                <div class="row">
                                <img alt="" style={{height: 500, width: 500 }}src={require("../../images/Aditya impex Jpg/mustard-oil-mill-500x500.jpg")}/>

                           
                                        <div class="col-md-12">
                                        <p>The Mustard Oil Mill Machine is known for cultivation of oil seeds and
                                        extraction of oil from them form and important part of Indian economy.
                                        Expellers are most commonly used machines for oil extraction and are more
                                        economical than Kolhus and Ghanies etc.</p>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">Features:</h3>
                                        <br/>
                                        <ul>
                                            <li>Accurate dimensions</li>
                                            <li>Longer service life</li>
                                            <li>Efficient working</li>
                                        </ul>
                                        <br/>
                                        
                                        <h3 class="text-black font-weight-600 m-b15">Measurement:</h3>
                                        <br/>
                                        <ul>
                                            <li>Length: 2 Meter</li>
                                            <li>Breadth: 1 Meter</li>
                                            <li>Height: 1.5 Meter</li>
                                        </ul>
                                        </div>

                            </div>
                        </div>
                                </div>


                            </div>

                        </div>
                        
                    </div>

                </div>


                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>
            </>
        )
    }
}
export default MustardOilMill;
