import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp'
const bg = require('../../images/banner/bnr4.jpg')

class WaterBottle extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Water Bottle Machine Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Project Division</li>
                                        <li>Water Bottle Machine Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Water Bottle Machine Details:</h2>
                                        <br/>
                                        
                                        <h3 class="text-black font-weight-600 m-b15">Product Specification:</h3>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Water Source</td>
                                                    <td style={{ border:"1px black solid" }}>Borewell Water</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Installation Services Required</td>
                                                    <td style={{ border:"1px black solid" }}>Yes</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Filtration Capacity (litre/hr)</td>
                                                    <td style={{ border:"1px black solid" }}>250-500, 500-1000, 1000-5000, 5000-10000, 1000</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Certifications</td>
                                                    <td style={{ border:"1px black solid" }}>ISI, BIS</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Laboratory</td>
                                                    <td style={{ border:"1px black solid" }}>Chemical Laboratory, Microbiological Laboratory</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Chilling Capacity (in tons) </td>
                                                    <td style={{ border:"1px black solid" }}>3 Ton, 5 Ton</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Chiller Capacity (in Liters)</td>
                                                    <td style={{ border:"1px black solid" }}>1000 L, 2000 L</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Bottle Filling Capacity (Bottles/min)</td>
                                                    <td style={{ border:"1px black solid" }}>50 Bottle/min</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Number of Membranes in RO</td>
                                                    <td style={{ border:"1px black solid" }}>2, 3, 4</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Control Panel</td>
                                                    <td style={{ border:"1px black solid" }}>With Control Panel</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Water Purification Process</td>
                                                    <td style={{ border:"1px black solid" }}>RO, Ozonation, Carbon Filtration, Chlorination</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Installation/Civil Work</td>
                                                    <td style={{ border:"1px black solid" }}>Installation Available</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Overall Dimensions (LxBxH) in meters</td>
                                                    <td style={{ border:"1px black solid" }}>Customised</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Automation Grade</td>
                                                    <td style={{ border:"1px black solid" }}>Automatic</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Approximate Power Consumption (in kW)</td>
                                                    <td style={{ border:"1px black solid" }}>7.5</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Manufacturing Lead Time</td>
                                                    <td style={{ border:"1px black solid" }}>3 week</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Bottle Sizes</td>
                                                    <td style={{ border:"1px black solid" }}>0-500 ml, 500ml-1 litre, 1 litre-5 litre, 10 litre- 20 litre</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Condition</td>
                                                    <td style={{ border:"1px black solid" }}>New</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Minimum Order Quantity</td>
                                                    <td style={{ border:"1px black solid" }}>1 Unit</td>
                                                </tr>


                                                 </tbody>
                                        </table> 
                                       

                                    </div>
                                    <div class="col-lg-6">
                                    <br/>
                                    <h3 class="text-black font-weight-600 m-b15">Product Description:</h3>
                                        <br/>
                                        <p>We offer 60 bpm bottle filling machine. 60 bpm bottle filling machine is use for water
                                        treatment plant to pack water bottle 200ml to 2 liter. 60 bpm bottle filling machine is
                                        fully automatic rinsing filling capping system.</p>
                                        <div class="row">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img alt="" style={{height: 500, width: 500 }}src={require("../../images/Aditya impex Jpg/Waterbottle Machine copy.jpg")}/>
                                        <br/>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>             
            </>
        )
    }
}
export default WaterBottle;
