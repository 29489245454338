import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class SolarStreetLight extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Solar Street Light Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Non Renewable Energy</li>
                                        <li>Solar Street Light Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Solar Street Light Details:</h2>
                                      <br/>
                                        <img alt="" style={{height: 400, width: 400 }}src={require("../../images/Aditya impex Jpg/solar-street-light copy.jpg")}/><br/>
                                        <br/>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">12w Solar Street Light</h3>
                                        <br/>
                                        <p>For 12 watt street solar LED light, the technical specifications are
                                        as below. You can check and compare it with other models of
                                        solar lights.</p>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Particular</th>
                                                    <th style={{ border:"1px black solid" }}>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Light</td>
                                                    <td style={{ border:"1px black solid" }}>12 watt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Panel</td>
                                                    <td style={{ border:"1px black solid" }}>50 watt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Battery</td>
                                                    <td style={{ border:"1px black solid" }}>40 AH</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>LED Bulb</td>
                                                    <td style={{ border:"1px black solid" }}>14 Nos.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>GI Pole </td>
                                                    <td style={{ border:"1px black solid" }}>5 Meter</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Charge Controller</td>
                                                    <td style={{ border:"1px black solid" }}>12 volt inbuilt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Sensor</td>
                                                    <td style={{ border:"1px black solid" }}>Dusk to Dawn inbuilt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Accessories</td>
                                                    <td style={{ border:"1px black solid" }}>Panel structure, wire, connectors nut bolts etc.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Backup Time</td>
                                                    <td style={{ border:"1px black solid" }}>24 hours</td>
                                                </tr>

                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Warranty</td>
                                                    <td style={{ border:"1px black solid" }}>5 years for complete </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Delivery</td>
                                                    <td style={{ border:"1px black solid" }}>7 days</td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">15w Solar Street Light</h3>
                                        <br/>
                                        <p>This is the most common size of solar light. If you want to install
                                        a solar light at your places, then 15 watt solar light is best option
                                        for you. Various features of 15 watt solar light is given below.</p>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Particular</th>
                                                    <th style={{ border:"1px black solid" }}>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Light</td>
                                                    <td style={{ border:"1px black solid" }}>15 watt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Panel</td>
                                                    <td style={{ border:"1px black solid" }}>75 watt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Battery</td>
                                                    <td style={{ border:"1px black solid" }}>60 AH</td>
                                                </tr>
                                               
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>GI Pole </td>
                                                    <td style={{ border:"1px black solid" }}>5 Meter</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Charge Controller</td>
                                                    <td style={{ border:"1px black solid" }}>12 volt inbuilt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Sensor</td>
                                                    <td style={{ border:"1px black solid" }}>Dusk to Dawn inbuilt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Accessories</td>
                                                    <td style={{ border:"1px black solid" }}>Panel structure, wire, connectors nut bolts etc.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Backup Time</td>
                                                    <td style={{ border:"1px black solid" }}>24 hours</td>
                                                </tr>

                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Warranty</td>
                                                    <td style={{ border:"1px black solid" }}>5 years for solar panel. </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Delivery</td>
                                                    <td style={{ border:"1px black solid" }}>7 days</td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                        <br/>
                                        


                                       

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div>
                                            <h3 class="text-black font-weight-600 m-b15">18w Solar Street Light</h3>
                                        <br/>
                                        <p>18 watt solar PV light is best solar solution to illuminate your
                                        gardens, grounds, parks etc. The specifications of 18 watt solar
                                        light is as mentioned below.</p>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Particular</th>
                                                    <th style={{ border:"1px black solid" }}>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Light</td>
                                                    <td style={{ border:"1px black solid" }}>18 watt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Panel</td>
                                                    <td style={{ border:"1px black solid" }}>75 watt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Battery</td>
                                                    <td style={{ border:"1px black solid" }}>60 AH</td>
                                                </tr>
                                               
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>GI Pole </td>
                                                    <td style={{ border:"1px black solid" }}>5 Meter</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Charge Controller</td>
                                                    <td style={{ border:"1px black solid" }}>12 volt inbuilt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Sensor</td>
                                                    <td style={{ border:"1px black solid" }}>Dusk to Dawn inbuilt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Accessories</td>
                                                    <td style={{ border:"1px black solid" }}>Panel structure, wire, connectors nut bolts etc.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Backup Time</td>
                                                    <td style={{ border:"1px black solid" }}>24 hours</td>
                                                </tr>

                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Warranty</td>
                                                    <td style={{ border:"1px black solid" }}>5 years for solar panel. </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Delivery</td>
                                                    <td style={{ border:"1px black solid" }}>7 days</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br/>
                                        <h3 class="text-black font-weight-600 m-b15">24w Solar Street Light</h3>
                                        <br/>
                                        <p>A 24 watt solar street LED light is useful for national highways,
                                        roads, factories and such places where more light is required. 24
                                        watt solar PV light is available for Rs.21,000. You can check its
                                        specification below.</p>
                                        <br/>
                                        <table style={{ border:"1px black solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border:"1px black solid" }}>Particular</th>
                                                    <th style={{ border:"1px black solid" }}>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Light</td>
                                                    <td style={{ border:"1px black solid" }}>24 watt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Panel</td>
                                                    <td style={{ border:"1px black solid" }}>100 watt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Solar Battery</td>
                                                    <td style={{ border:"1px black solid" }}>75 AH</td>
                                                </tr>
                                               
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>GI Pole </td>
                                                    <td style={{ border:"1px black solid" }}>5 Meter</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Charge Controller</td>
                                                    <td style={{ border:"1px black solid" }}>12 volt inbuilt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Sensor</td>
                                                    <td style={{ border:"1px black solid" }}>Dusk to Dawn inbuilt</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Accessories</td>
                                                    <td style={{ border:"1px black solid" }}>Panel structure, wire, connectors nut bolts etc.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Backup Time</td>
                                                    <td style={{ border:"1px black solid" }}>24 hours</td>
                                                </tr>

                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Warranty</td>
                                                    <td style={{ border:"1px black solid" }}>5 years for solar panel. </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border:"1px black solid" }}>Delivery</td>
                                                    <td style={{ border:"1px black solid" }}>7 days</td>
                                                </tr>
                                            </tbody>
                                        </table> 

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter hideContactInfo displayNewsLetter />
                <ScrollToTop className="icon-up"/>     
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>            
            </>
        )
    }
}
export default SolarStreetLight;
