import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../layout/homeheader'
import HomeFooter from '../layout/homefooter'
import ScrollToTop from '../element/scrollToTop';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
}
    from 'react-floating-button-menu';
import MdAdd from '@material-ui/icons/add';
import MdClose from '@material-ui/icons/clear';
import MdWhatsApp from '@material-ui/icons/WhatsApp';
const bg = require('../../images/banner/bnr4.jpg')

class ChoperMillDetails extends Component {

    state = {
        isOpen: false,
        
    }
    render() {
        return (
            <>
                <HomeHeader/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Chopper Mill Details</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>
                                            Segments</li>
                                        <li>Agriculture Equipments</li>
                                        <li>Chopper Mill Details</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 m-b30">
                                        <h2 class="text-black font-weight-600 m-b15">Chopper Mill Details:</h2>
                                        <br/>
                                        <h3>TECHNICAL SPECIFICATION :</h3>
                                        <br/>
                                        <ul>
                                            <li> RECOMMENDED PRIME MOVER 5/6.5 HP A/C DIESEL ENGINE OR 3 HP ELECTRIC MOTOR</li>
                                            <li>PRODUCTION CAPACITY 120 TO 150 KGS PER HOURS BASED ON CONDITION OF MEDIA AND WORKERS SKILL</li>
                                            <li>SPEED 3750 RPM</li>
                                            <li>WE CAN USED THIS MACHINE TO CHOP SOFT CROPS LIKE CASSAVA, POTATO. MEAT, ETC.....</li>
                                            <li> WE HAVE USED HARDEN BLADES WHICH PERFORM LONG LIFE OF MACHINE</li>
                                            <li>APPROX WEIGHT 55 TO 60 KGS WITHOUT ENGINE</li>
                                            <li>CHOPPER COMPRISING OF STD ACC LIKE FEEDING HOPPER, FRAME FOR FITTING ENGINE, VPULLEY FOR CHOPPER, SAFETY GUARD, V-BELTS AND STD TOLL KIT.</li>

                                        </ul>
                                        

                                        <br/>

                                        <h3>SILENT FEATURES :</h3>
                                        <br/>

                                        <ul>
                                            <li>WE HAVE DESIGNED HOOPER ACROSS THE LENGTH OF DRUM SO AS WE CAN INPUT MAXIMUM MATERIAL AND CAN GET SUFFICIENT OUTPUT.</li>
                                            <li>WE HAVE DESIGNED CHASSIS OF THE CHOPPER IN SUCH A WAY THAT YOU CAN GET SUFFICIENT
                                            RECOVERY OF FINISHED PRODUCT</li>
                                        </ul>
                                        <br/>
                                        <p>tric motor or diesel engine, for your convenience</p>
                                        <br/>
                                    </div>
                                    <div class="col-lg-6">
                                    <br/><br/><br/><br/><br/>

                                        <div class="row">.
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img alt="" style={{height: 400, width: 400 }}src={require("../../images/Aditya impex Jpg/2.jpg")}/>
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <HomeFooter  hideContactInfo displayNewsLetter/>    
                <ScrollToTop className="icon-up"/>       
                <FloatingMenu style={{ backgroundColor: "green"}}
                            slideSpeed={500}
                            direction="up"
                            style={{color:'black' , bottom: '0px' , marginLeft: '95%' , position:'fixed'}}
                            spacing={8}
                            isOpen={this.state.isOpen}
                            class="chat"
                        >
                            <MainButton style={{ backgroundColor: "#ff5e14"}}
                                iconResting={<MdAdd style={{ fontSize: 20 }} nativeColor="white" />}
                                iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
                                backgroundColor="black"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                size={56}
                            />
                            <ChildButton style={{ backgroundColor: "green"}}
                            
                                icon={<MdWhatsApp style={{ fontSize: 20 , color: "green" }}  nativeColor="black" />}
                                backgroundColor="green"
                                size={50}
                                onClick={() => window.open("https://wa.me/+919913344447", "_blank")}
                            />
                            {/* <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            />
                            <ChildButton
                                icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
                                backgroundColor="white"
                                size={40}
                            /> */}
                        </FloatingMenu>               
            </>
        )
    }
}
export default ChoperMillDetails;