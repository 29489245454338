import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Industry from './pages/industry';
import Home from './pages/home';
import Construction from './pages/construction';
import NonRenewableEnergy from './pages/nonRenewableEnergy';
import ComponentDevelopment from './pages/componentDevelopment';
import Agriculture from './pages/agriculture';
import Harvesting from './pages/harvesting';
import FoodProcessing from './pages/foodProcessing';
import AgriculturePortfolio from './pages/agriculture-portfolio';
import HarvestingPortfolio from './pages/harvesting-portfolio';
import PostHarvestingPortfolio from './pages/post-harvesting-portfolio';
import OtherHarvestingPortfolio from './pages/other-harvesting-portfolio';



import FoodPortfolio from './pages/food-portfolio';
import ConstructionPortfolio from './pages/construction-portfolio';
import WorkshopPortfolio from './pages/workshop-portfolio';
import ComponentPortfolio from './pages/component-portfolio';
import RenewablePortfolio from './pages/renewable-portfolio';
import TenderPortfolio from './pages/tender-portfolio';
import Contactus from './pages/contactus';
import Aboutus from './pages/aboutus';
import TurbinWaterPumpsDetails from './pages/Turbin-Water-Pumps-Details';
import SubmersiblePumpsDetails from './pages/Submersible-Pumps-Details';
import CentrifugalPumpDetails from './pages/centrifugal-pump-details';
import CpvcPipeDetails from './pages/cpvc-pipe-details';
import ListerDetails from './pages/lister-details';
import PetterEngineSpareDetails from './pages/petter-engine-spare-details';
import ListerEngineSpareDetails from './pages/lister-engine-spare-details';
import ScutionHosePipeDetails from './pages/suction-hose-pipe-details';
import ChaffCutterDetails from './pages/chaff-cutter-details';
import PloughingDetailsPre from './pages/ploughing-details-pre';
import PeterTypeEngine from './pages/peter-type-engine-details';
import RoundBar from './pages/round-bar-details';






import CultivatorDetails from './pages/cultivator-details';
import PloughingDetails from './pages/ploughing-details';
import SeedDrillDetails from './pages/seed-drill-details';
import SeedPlanterMachineDetails from './pages/seed-planter-machine';

import GriddingMillDetails from './pages/gridding-mill-details';
import PalmOilDetails from './pages/palm-oil-details';
import DaliaMakingDetails from './pages/dalia-making-details';
import CashewNutDetails from './pages/cashew-nut-machine-details';
import GrainColorDetails from './pages/grain-color-details';
import RiceColorDetails from './pages/rice-color-details';
import CornOilDetails from './pages/corn-oil-details';
import VegFruitCuttingDetails from './pages/vegFruit-cutting-details';

import HydraulicDetails from './pages/hydraulic-details';
import TowerLiftDetails from './pages/tower-lift-details';
import HopperMachine from './pages/hopper-machine-details';
import NonHopperMachine from './pages/non-hopper-machine-details';
import NeedkeVibrator from './pages/needke-vibrator-details';
import PaverBlock from './pages/paver-block-details';
import SoilCompactor from './pages/soil-compactor-details';
import AutomaticPlaster from './pages/automatic-plaster-details';


import AirCompressor from './pages/air-compressor-details';
import LathMachine from './pages/lath-machine-details';
import PortableWelding from './pages/portable-welding-details';
import AllTypePowerTools from './pages/all-type-power-tools-details';
import ElectricMotor from './pages/electric-motor-details';
import DrillMachine from './pages/drill-machine-details';


import SandCasting from './pages/sand-casting-details';
import ShellCoreCasting from './pages/shell-core-details';
import InvestmentCasting from './pages/investment-casting-details';
import GravityCasting from './pages/gravity-casting-details';
import PDCCasting from './pages/pdc-casting-details';
import DIEForging from './pages/die-forging-details';
import RingRolling from './pages/ring-rolling-details';
import CarbonSteel from './pages/carbon-steel-details';
import AlloySteel from './pages/alloy-steel-details';
import NonAlloySteel from './pages/non-alloy-steel-details';
import BarStock from './pages/bar-stock-details';




import SolarHomeKit from './pages/solarhomekit-details';
import SolarPump from './pages/solar-pump-details';
import SolarSystem from './pages/solar-system-details';
import SolarLantern from './pages/solar-lantern-details';
import SolarStreetLight from './pages/solar-street-light-details';


import PouchPowder from './pages/pouch-powder-details';
import DalMillPlant from './pages/dal-mill-plant-details';
import MiniSuger from './pages/mini-suger-plant-details';
import NonVovenBagPlant from './pages/non-voven-bag-plant-details';
import DoubleRoller from './pages/double-roller-details';
import SugerPlantMachinery from './pages/suger-plant-machine-details';
import CupFilling from './pages/cup-filling-details';
import WaterBottle from './pages/water-bottle';
import MiniOilMillPlant from './element/mini-oil-mill-plant-details';
import ColdStorage from './pages/cold-storage-details';
import Generator from './pages/generator-details';
import CandleMakingMachine from './pages/candle-making-details';


import SeedCleaningMachine from './pages/seed-cleaning-machine-details';
import DrumCleaner from './pages/drum-cleaner-details';
import WheatCleaningMachine from './pages/wheat-cleaning-machine';



import OtherHarvestSeedDrillDetails from './pages/other-harvest-seed-drill-details';
import DrippSystemDetails from './pages/dripp-system-details';
import PolyDiscHarrow from './pages/poly-dics-harrow-details';
import HarvestingMachineRepare from './pages/harvesting-machine-repare-details';

import BearingDetails from './pages/bearing-details';
import ElectricCable from './pages/electric-cable-details';

import ChoperMillDetails from './pages/choper-mill-details';

import TenderPortfolio2 from './pages/tender-portfolio2';

import ColdPressOilDetails from './pages/cold-press-oil-details';
import OilFilterDetails from './pages/oil-filter-machine-details';
import GroundnutsOilMachine from './pages/groundnuts-oil-machine';
import OilGhaniMachine from './pages/oil-ghani-machine';
import MustardOilMill from './pages/mustard-oil-mill-machine';











import Workshop from './pages/workshop';
import Tender from './pages/tender';
import About1 from './pages/about-1';
import About2 from './pages/about-2';
import BlogGrid2Sidebar from './pages/blog-grid-2-sidebar';
import BlogGrid3 from './pages/blog-grid-3';
import BlogHalfImgSidebar from './pages/blog-half-img-sidebar';
import BlogLargeImgSidebar from './pages/blog-large-img-sidebar';
import BlogSingleSidebar from './pages/blog-single-sidebar';
import ComingSoon1 from './pages/coming-soon-1';
import Contact1 from './pages/contact-1';
import Contact2 from './pages/contact-2';
import Contact3 from './pages/contact-3';
import Contact4 from './pages/contact-4';
import Error403 from './pages/error-403';
import Error404 from './pages/error-404';
import Error405 from './pages/error-405';
import Faq1 from './pages/faq-1';
import Faq2 from './pages/faq-2';
import HelpDesk from './pages/help-desk';
import PortfolioDetails from './pages/portfolio-details';
import HandPumpDetails from './pages/hand-pump-details';
import PortfolioGrid2 from './pages/portfolio-grid-2';
import PortfolioGrid3 from './pages/portfolio-grid-3';
import PortfolioGrid4 from './pages/portfolio-grid-4';
import PrivacyPolicy from './pages/privacy-policy';
import Services1 from './pages/services-1';
import Services2 from './pages/services-2';
import ServicesDetails from './pages/services-details';
import ShopCart from './pages/shop-cart';
import ShopCheckout from './pages/shop-checkout';
import ShopLogin from './pages/shop-login';
import shopProductDetails from './pages/shop-product-details';
import ShopRegister from './pages/shop-register';
import ShopSidebar from './pages/shop-sidebar';
import ShopWishlist from './pages/shop-wishlist';
import Shop from './pages/shop';
import Team1 from './pages/team-1';
import Team2 from './pages/team-2';
import Index2 from './pages/index-2';
import Index4 from './pages/index-4';
import Index7 from './pages/index-7';
import Index9 from './pages/index-9';
import Index10 from './pages/index-10';
import Index11 from './pages/index-11';
import Index12 from './pages/index-12';
import Index13 from './pages/index-13';
import Index15 from './pages/index-15';


class Router extends Component {
    render() {
        return (
            //<BrowserRouter>
             <BrowserRouter basename={''}>
                <div class="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Home} />
                        <Route path='/agriculture' component={Agriculture} />
                        <Route path='/harvesting' component={Harvesting} />
                        <Route path='/foodProcessing' component={FoodProcessing} />
                        <Route path='/tender' component={Tender} />
                        <Route path='/componentDevelopment' component={ComponentDevelopment} />
                        <Route path='/nonRenewableEnergy' component={NonRenewableEnergy} />
                        <Route path='/construction' component={Construction} />
                        <Route path='/workshop' component={Workshop} />
                        <Route path='/industry' exact component={Industry} />
                        <Route path='/agriculture-portfolio' component={AgriculturePortfolio} />
                        <Route path='/harvesting-portfolio' component={HarvestingPortfolio} />
                        <Route path='/post-harvesting-portfolio' component={PostHarvestingPortfolio} />
                        <Route path='/other-harvesting-portfolio' component={OtherHarvestingPortfolio} />


                        <Route path='/food-portfolio' component={FoodPortfolio} />
                        <Route path='/construction-portfolio' component={ConstructionPortfolio} />
                        <Route path='/workshop-portfolio' component={WorkshopPortfolio} />
                        <Route path='/component-portfolio' component={ComponentPortfolio} />
                        <Route path='/renewable-portfolio' component={RenewablePortfolio} />
                        <Route path='/tender-portfolio' component={TenderPortfolio} />
                        <Route path='/contactus' component={Contactus} />
                        <Route path='/aboutus' component={Aboutus} />
                        <Route path='/hand-pump-details' component={HandPumpDetails} />
                        <Route path='/Turbin-Water-Pumps-Details' component={TurbinWaterPumpsDetails} />
                        <Route path='/Submersible-Pumps-Details' component={SubmersiblePumpsDetails} />
                        <Route path='/centrifugal-pump-details' component={CentrifugalPumpDetails} />
                        <Route path='/cpvc-pipe-details' component={CpvcPipeDetails} />
                        <Route path='/lister-details' component={ListerDetails} />
                        <Route path='/petter-engine-spare-details' component={PetterEngineSpareDetails} />
                        <Route path='/lister-engine-spare-details' component={ListerEngineSpareDetails} />
                        <Route path='/suction-hose-pipe-details' component={ScutionHosePipeDetails} />
                        <Route path='/chaff-cutter-details' component={ChaffCutterDetails} />
                        <Route path='/ploughing-details-pre' component={PloughingDetailsPre} />
                        <Route path='/peter-type-engine-details' component={PeterTypeEngine} />
                        <Route path='/round-bar-details' component={RoundBar} />





                        <Route path='/cultivator-details' component={CultivatorDetails} />
                        <Route path='/ploughing-details' component={PloughingDetails} />
                        <Route path='/seed-drill-details' component={SeedDrillDetails} />
                        <Route path='/seed-planter-machine' component={SeedPlanterMachineDetails} />


                        <Route path='/gridding-mill-details' component={GriddingMillDetails} />
                        <Route path='/palm-oil-details' component={PalmOilDetails} />
                        <Route path='/dalia-making-details' component={DaliaMakingDetails} />
                        <Route path='/cashew-nut-machine-details' component={CashewNutDetails} />
                        <Route path='/grain-color-details' component={GrainColorDetails} />
                        <Route path='/rice-color-details' component={RiceColorDetails} />
                        <Route path='/corn-oil-details' component={CornOilDetails} />
                        <Route path='/vegFruit-cutting-details' component={VegFruitCuttingDetails} />


                        <Route path='/hydraulic-details' component={HydraulicDetails} />
                        <Route path='/tower-lift-details' component={TowerLiftDetails} />
                        <Route path='/hopper-machine-details' component={HopperMachine} />
                        <Route path='/non-hopper-machine-details' component={NonHopperMachine} />
                        <Route path='/needke-vibrator-details' component={NeedkeVibrator} />
                        <Route path='/paver-block-details' component={PaverBlock} />
                        <Route path='/soil-compactor-details' component={SoilCompactor} />
                        <Route path='/automatic-plaster-details' component={AutomaticPlaster} />


                        <Route path='/air-compressor-details' component={AirCompressor} />
                        <Route path='/lath-machine-details' component={LathMachine} />
                        <Route path='/portable-welding-details' component={PortableWelding} />
                        <Route path='/all-type-power-tools-details' component={AllTypePowerTools} />
                        <Route path='/electric-motor-details' component={ElectricMotor} />
                        <Route path='/drill-machine-details' component={DrillMachine} />


                        <Route path='/sand-casting-details' component={SandCasting} />
                        <Route path='/shell-core-details' component={ShellCoreCasting} />
                        <Route path='/investment-casting-details' component={InvestmentCasting} />
                        <Route path='/gravity-casting-details' component={GravityCasting} />
                        <Route path='/pdc-casting-details' component={PDCCasting} />
                        <Route path='/die-forging-details' component={DIEForging} />
                        <Route path='/ring-rolling-details' component={RingRolling} />
                        <Route path='/carbon-steel-details' component={CarbonSteel} />
                        <Route path='/alloy-steel-details' component={AlloySteel} />
                        <Route path='/non-alloy-steel-details' component={NonAlloySteel} />
                        <Route path='/bar-stock-details' component={BarStock} />



                        <Route path='/solarhomekit-details' component={SolarHomeKit} />
                        <Route path='/solar-pump-details' component={SolarPump} />
                        <Route path='/solar-system-details' component={SolarSystem} />
                        <Route path='/solar-lantern-details' component={SolarLantern} />
                        <Route path='/solar-street-light-details' component={SolarStreetLight} />
                        <Route path='/pouch-powder-details' component={PouchPowder} />
                        <Route path='/dal-mill-plant-details' component={DalMillPlant} />
                        <Route path='/mini-suger-plant-details' component={MiniSuger} />
                        <Route path='/non-voven-bag-plant-details' component={NonVovenBagPlant} />
                        <Route path='/double-roller-details' component={DoubleRoller} />
                        <Route path='/suger-plant-machine-details' component={SugerPlantMachinery} />
                        <Route path='/cup-filling-details' component={CupFilling} />
                        <Route path='/water-bottle' component={WaterBottle} />
                        <Route path='/mini-oil-mill-plant-details' component={MiniOilMillPlant} />
                        <Route path='/cold-storage-details' component={ColdStorage} />
                        <Route path='/generator-details' component={Generator} />
                        <Route path='/candle-making-details' component={CandleMakingMachine} />






                        <Route path='/seed-cleaning-machine-details' component={SeedCleaningMachine} />
                        <Route path='/drum-cleaner-details' component={DrumCleaner} />
                        <Route path='/wheat-cleaning-machine' component={WheatCleaningMachine} />




                        <Route path='/other-harvest-seed-drill-details' component={OtherHarvestSeedDrillDetails} />
                        <Route path='/dripp-system-details' component={DrippSystemDetails} />
                        <Route path='/poly-dics-harrow-details' component={PolyDiscHarrow} />
                        <Route path='/harvesting-machine-repare-details' component={HarvestingMachineRepare} />

                        <Route path='/bearing-details' component={BearingDetails} />
                        <Route path='/electric-cable-details' component={ElectricCable} />

                        <Route path='/choper-mill-details' component={ChoperMillDetails} />

                        <Route path='/tender-portfolio2' component={TenderPortfolio2} />

                        <Route path='/cold-press-oil-details' component={ColdPressOilDetails} />
                        <Route path='/oil-filter-machine-details' component={OilFilterDetails} />
                        <Route path='/groundnuts-oil-machine' component={GroundnutsOilMachine} />
                        <Route path='/oil-ghani-machine' component={OilGhaniMachine} />
                        <Route path='/mustard-oil-mill-machine' component={MustardOilMill} />









                        




                        <Route path='/about-1' component={About1} />
                        <Route path='/about-2' component={About2} />
                        <Route path='/blog-grid-2-sidebar' component={BlogGrid2Sidebar} />
                        <Route path='/blog-grid-3' component={BlogGrid3} />
                        <Route path='/blog-half-img-sidebar' component={BlogHalfImgSidebar} />
                        <Route path='/blog-large-img-sidebar' component={BlogLargeImgSidebar} />
                        <Route path='/blog-single-sidebar' component={BlogSingleSidebar} />
                        <Route path='/coming-soon-1' component={ComingSoon1} />
                        <Route path='/contact-1' component={Contact1} />
                        <Route path='/contact-2' component={Contact2} />
                        <Route path='/contact-3' component={Contact3} />
                        <Route path='/contact-4' component={Contact4} />
                        <Route path='/error-403' component={Error403} />
                        <Route path='/error-404' component={Error404} />
                        <Route path='/error-405' component={Error405} />
                        <Route path='/faq-1' component={Faq1} />
                        <Route path='/faq-2' component={Faq2} />
                        <Route path='/help-desk' component={HelpDesk} />
                        <Route path='/portfolio-details' component={PortfolioDetails} />
                        <Route path='/portfolio-grid-2' component={PortfolioGrid2} />
                        <Route path='/portfolio-grid-3' component={PortfolioGrid3} />
                        <Route path='/portfolio-grid-4' component={PortfolioGrid4} />
                        <Route path='/privacy-policy' component={PrivacyPolicy} />
                        <Route path='/services-1' component={Services1} />
                        <Route path='/services-2' component={Services2} />
                        <Route path='/services-details' component={ServicesDetails} />
                        <Route path='/shop-cart' component={ShopCart} />
                        <Route path='/shop-checkout' component={ShopCheckout} />
                        <Route path='/shop-login' component={ShopLogin} />
                        <Route path='/shop-product-details' component={shopProductDetails} />
                        <Route path='/shop-register' component={ShopRegister} />
                        <Route path='/shop-sidebar' component={ShopSidebar} />
                        <Route path='/shop-wishlist' component={ShopWishlist} />
                        <Route path='/shop' component={Shop} />
                        <Route path='/team-1' component={Team1} />
                        <Route path='/team-2' component={Team2} />
                        <Route path='/index-2' component={Index2} />
                        <Route path='/index-4' component={Index4} />
                        <Route path='/index-7' component={Index7} />
                        <Route path='/index-9' component={Index9} />
                        <Route path='/index-10' component={Index10} />
                        <Route path='/index-11' component={Index11} />
                        <Route path='/index-12' component={Index12} />
                        <Route path='/index-13' component={Index13} />
                        <Route path='/index-15' component={Index15} />

                    </Switch>
                </div>

            </BrowserRouter>

        )
    }
}

export default Router;